import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';

// Hook
function useConsentCheck() {
  const [consentAccepted, setConsentAccepted] = useState(false);
  const isBrowser = typeof window !== "undefined";
  const [cookies] = useCookies();

  useEffect(() => {
    if (isBrowser && cookies?.adult) {
      const checkKetchConsent = setInterval(() => {
        if (window.ketchConsent) {
          if (window.ketchConsent.behavioral_advertising) {
            setConsentAccepted(true);
          }

          window.semaphore.push(['onHideExperience', function () {
            if (window.ketchConsent.behavioral_advertising) {
              setConsentAccepted(true);
            } else {
              setConsentAccepted(false);
            }
          }]);

          clearInterval(checkKetchConsent);
        }
      }, 300);
      return () => clearInterval(checkKetchConsent);
    }
  }, [consentAccepted,cookies?.adult, isBrowser]);

  return consentAccepted;
}

export default useConsentCheck;