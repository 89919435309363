// core
import React, { useState, useEffect, useRef, createRef } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { useCookies } from 'react-cookie'

// components
import CountrySelector from './CountrySelector'
import ImgWithFallback from './ImgWithFallback'

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareFacebook, faSquareInstagram, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons'
import whiteClawLogoPNG from '../assets/images/whiteclaw-logo-blk-120x120.png'
import whiteClawLogoWEBP from '../assets/images/whiteclaw-logo-blk-120x120.webp'
import globe from '../assets/images/icons/globe.svg'

// preview
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

// data-layer
import { setBuyNowClickedEvent, setSocialIconClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

// constants
import { SOCIAL_PLATFORM } from "../constants/socials";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

//assets
import useWindowSize from "../hooks/useWindowSize";

const Header = ( { disabledMenu, customPage, activeDocMeta,header_navigation,isSticky,showBannerCustomPageType,showBanner,showGenericPageBanner,showWyngPageBanner } ) => {
  // console.log(activeDocMeta)
  const { lang } = activeDocMeta ? activeDocMeta : { lang: "en-us" }
  const { width } = useWindowSize()
  const productNavRef = createRef()

  const queryData = useStaticQuery( graphql`
  {
    EN: prismicNavigation(lang: { eq: "en-us" }) {
      id
      _previewable
      data {
        body {
          ... on PrismicNavigationDataBody1stLevel {
            slice_type
            primary {
              link_text
              link_id
              nav_link {
                url
                slug
                id
                link_type
              }
            }
          }
          ... on PrismicNavigationDataBody2ndLevel {
            slice_type
            items {
              third_level_link_text
              third_level_link {
                slug
                url
                id
                link_type
              }
              third_level_link_image{
                url
              }
            }
            primary {
              link_text
            }
          }
        }
        hover_menu
      }
    }
    prismicProductRange(lang: { eq: "en-us" }) {
      data {
        product_sections {
          section_item {
            document {
              ... on PrismicProductSection {
                id
                data {
                  nav_view_all_link {
                    text
                  }
                  nav_view_all_text {
                    text
                  }
                  section_name
                  section_new_text
                  section_categories {
                    product_category {
                      document {
                        ... on PrismicProductCategory {
                          id
                          data {
                            category_name
                            category_new_text
                            navigation_image{
                              url
                              alt
                            }
                            new_tag {
                              text
                            }
                            category_item {
                              category_item_link {
                                document {
                                  ... on PrismicProductDetailsPage {
                                    id
                                    url
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ES: prismicNavigation(lang: { eq: "es-mx" }) {
      id
      _previewable
      data {
        body {
          ... on PrismicNavigationDataBody1stLevel {
            slice_type
            primary {
              link_text
              link_id
              nav_link {
                url
                slug
                id
                link_type
              }
            }
          }
          ... on PrismicNavigationDataBody2ndLevel {
            slice_type
            items {
              third_level_link_text
              third_level_link {
                slug
                url
                id
                link_type
              }
            }
            primary {
              link_text
            }
          }
        }
      }
    }
    prismicCountrySelector {
      data {
        body {
          ... on PrismicCountrySelectorDataBodyCountryName {
            id
            items {
              flag {
                url
              }
              countrylanguageid
              langtag
            }
          }
        }
      }
    }
  }
  `)

  const { data } = useMergePrismicPreviewData( queryData )
  const pageData = lang === 'en-us' ? data.EN.data : data.ES.data
  const navigation = pageData.body
  const products = data.prismicProductRange
  const countryData = data.prismicCountrySelector?.data?.body[ 0 ]?.items[ 0 ]?.flag?.url
  const [ showCollapsedNavbar, setShowCollapsedNavbar ] = useState( false )
  const [ scrollNavbar, setScrollNavbar ] = useState( false )
  const [ activeDropdownMenu, setActiveDropdownMenu ] = useState( false );
  const dropdownRef = useRef( null );
  const consentAccepted = useConsent()
  const [cookies] = useCookies()
  const [persID, setPersID] = useState(false)
  const ourStoryRef = createRef( null );
  const headerNavigation=header_navigation;
  const hoverMenu=pageData?.hover_menu;

  // console.log('pageData', pageData)

  // country select functions
  const [ showCountrySelector, setShowCountrySelector ] = useState( false )
  const closeCountrySelector = () => setShowCountrySelector( false )
  const openCountrySelector = () => setShowCountrySelector( true )

  // scroll func
  const handleScroll = () => {
    window.scrollY < 90 ? setScrollNavbar( false ) : setScrollNavbar( true )
    setActiveDropdownMenu( null )
  }

  const handleSecondLevelMenuClick = ( itemID ) => {
    setActiveDropdownMenu( itemID )
  }
  const handleSecondLevelMenuClickOnStory = ( e ) => {
    setActiveDropdownMenu( null )
    setTimeout(() => {
      setActiveDropdownMenu(false);
    }, 10);
  }

  const handleCloseOnWindowClick = ( e ) => {
    if ( dropdownRef?.current && !dropdownRef?.current.contains( e.target ) ) {
      setActiveDropdownMenu( null )
    }
    if ( ourStoryRef?.current && !ourStoryRef?.current.contains( e.target ) ) {
      setActiveDropdownMenu( null )
    }
  }

  const handleWindowBlur = () => {
    setActiveDropdownMenu( null )
  }

  useEffect(() => {
    // console.log('*************************************')
    // console.log('*************************************')
    // console.log('cookie useEffect run')
    if (!cookies?.persID) {
      // console.log('!cookies?.persID ... returning')
      return
    }
    setPersID(cookies.persID)
    // console.log('setPersID(cookies.persID)... was just set')
    // console.log('end of useEffect persID', persID)
    // console.log('*************************************')
    // console.log('*************************************')
  }, [cookies?.persID])

  useEffect( () => {
    if ( !showCollapsedNavbar ) {
      window.addEventListener( "click", handleCloseOnWindowClick )
      window.addEventListener( "blur", handleWindowBlur )
    } else {
      window.removeEventListener( "click", handleCloseOnWindowClick )
      window.removeEventListener( "blur", handleWindowBlur )
    }
    return () => {
      window.removeEventListener( "click", handleCloseOnWindowClick )
      window.removeEventListener( "blur", handleWindowBlur )
    }
  }, [ showCollapsedNavbar ] )

  useEffect( () => {
    // add event listener
    window.addEventListener( "scroll", handleScroll )
    document.body.classList.remove( 'overflow-hidden' )
    document.documentElement.className = ''
    return () => window.removeEventListener( "scroll", handleScroll )
  }, [ scrollNavbar ] )

  useEffect( () => {
    if ( productNavRef?.current && width >= 991 && productNavRef.current.scrollWidth !== productNavRef.current.clientWidth) { //desktop
      const right = productNavRef.current.scrollWidth - productNavRef.current.clientWidth === Math.round( productNavRef.current.scrollLeft );
      if ( right ) {
        document.getElementById( 'product-prev-nav' ).style.visibility = 'visible'
        document.getElementById( 'product-next-nav' ).style.visibility = 'hidden'
      }
      const left = productNavRef.current.scrollLeft === 0
      if ( left ) {
        document.getElementById( 'product-next-nav' ).style.visibility = 'visible'
        document.getElementById( 'product-prev-nav' ).style.visibility = 'hidden'
      }
    }
    else{
      if(document.getElementById( 'product-next-nav' ) != null )
          document.getElementById( 'product-next-nav' ).style.visibility = 'hidden'
      if(document.getElementById( 'product-prev-nav' ) != null )
          document.getElementById( 'product-prev-nav' ).style.visibility = 'hidden'
    }
  }, [ productNavRef, width] )


  let headerClass = ( scrollNavbar ) ? ' scrolled' : ''
  let isStickyClass = (!isSticky && (scrollNavbar) && "header--not-sticky")

  // if (disabledMenu) { headerClass += " header--age-gate" }

  const onSocialIconClick = ( platform ) => {
    const dl = {
      platform,
      location: 'header'
    }
    setSocialIconClickedEvent( dl, consentAccepted )
  }

  const onBuyNowClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const dl = {
      flavor: '',
      category: ''
    }
    setBuyNowClickedEvent( dl, consentAccepted )
    window.location.href = event.target.href;
  }

  const onCloseCrossIcon = () => {
    setShowCollapsedNavbar( false )
    document.body.classList.remove( "go_back_container" );
  }

  const closeOurStoryNavBar = () => {
    document.body.classList.remove( 'overflow-hidden' )
    document.documentElement.className = ''
    window.removeEventListener( "click", handleCloseOnWindowClick )
    handleSecondLevelMenuClickOnStory();
    setShowCollapsedNavbar( false )
  }

  const handleScrollProduct = ( e ) => {
    if ( productNavRef?.current && width >= 991) { //desktop
    const right = e.target.scrollWidth - e.target.clientWidth === Math.round( e.target.scrollLeft );
    if ( right ) {
      document.getElementById( 'product-prev-nav' ).style.visibility = 'visible'
      document.getElementById( 'product-next-nav' ).style.visibility = 'hidden'
    }
    const left = e.target.scrollWidth !== e.target.clientWidth && e.target.scrollLeft === 0;
    if ( left ) {
      document.getElementById( 'product-next-nav' ).style.visibility = 'visible'
      document.getElementById( 'product-prev-nav' ).style.visibility = 'hidden'
    }
  }
  }

  const openNewScreen = ( element ) => {
    if ( element.primary.nav_link.link_type === "Document" ) {
      window.location.href = element.primary.nav_link.url;
    } else {
      if ( element.primary?.link_id === "faq" || element.primary?.link_id === "locator" ) {
        window.open( `${element.primary.nav_link.url.includes('?') ? element.primary.nav_link.url + '&' : element.primary.nav_link.url + '?'}persID=${ persID }&adult=${cookies?.adult}&ag=${!cookies?.adult}`, '_blank' );
      } else {
        window.location.href = element.primary.nav_link.url;
      }
    }
  }

  const styles={
    navItem:{
      padding:width<=991&&"1.3vh 0"
    },
    countrySelectorBottom:{
      bottom:width<=991 ? "40px" :0
    },
    socialMobileView:{
      marginTop:width<=991&&0
    },
  }
  // render header menus
  let headerPosition=false;
  if(showBannerCustomPageType[activeDocMeta?.type]!==false||showBannerCustomPageType[activeDocMeta?.type]===undefined) {
    if((activeDocMeta?.type==="generic_page"||activeDocMeta?.type==="wyng_page")) {
      if((showGenericPageBanner||showGenericPageBanner===null)||(showWyngPageBanner||showWyngPageBanner===null)) {
        headerPosition=true
      }
      else {
        headerPosition=false
      }
    } else {
      headerPosition=true
    }
  } else {
    headerPosition=false
  }
  return (
    <>
      <header className={ ( customPage && customPage != "tombogo" )
        ? `header ${headerNavigation==='Hidden'  ?  "hidden-important" : "" } d-flex align-items-center header--${customPage} ${headerClass} ${headerNavigation==='Black'? 'black-appearance' : headerNavigation==='Hidden' ? 'hidden-appearance' : 'white-appearance'} ${(lang === 'en-us' && showBanner && headerPosition) ?  isStickyClass : ""} ${(lang === 'en-us' && showBanner && (headerPosition) && !cookies?.dismissBanner) ? "header--banner" : ""}`
        : `header ${headerNavigation==='Hidden'  ?  "hidden-important" : "" } d-flex align-items-center ${headerClass} ${headerNavigation==='Black'? 'black-appearance' : headerNavigation==='Hidden' ? 'hidden-appearance' : 'white-appearance'} ${(lang === 'en-us' && showBanner && headerPosition) ?  isStickyClass : ""} ${(lang === 'en-us' && showBanner && (headerPosition ) && !cookies?.dismissBanner) ? "header--banner" : ""}`
      } id='header'>

        <div className="container-fluid">
          <nav className="navbar-expand-lg navbar" role="navigation">
            {
              <div className="header__logo">
                <Link to={ lang === 'en-us' ? '/' : '/es/' } target="_self" title="White Claw">
                  <ImgWithFallback
                    classNamePicture={ "header__logo-pct" }
                    classNameImg={ "header__logo-img" }
                    src={ whiteClawLogoWEBP }
                    fallbackSrc={ whiteClawLogoPNG }
                    alt="White Claw Logo"
                  />
                </Link>
                { (  lang === 'en-us' && (activeDropdownMenu === 2 || activeDropdownMenu === 1) ) &&
                  <div className='go_back_container' ref={ourStoryRef}>
                    <div className='header go_back_header'>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
                      <div
                        onClick={ ( e ) => handleSecondLevelMenuClickOnStory( e ) }
                        className='go-back_btn'>
                        <FontAwesomeIcon icon={ faChevronLeft } className="fa-solid mt-1 back-icon" size="lg" />
                        <span className='current-menu-title'>{ activeDropdownMenu === 2 ? "Our Story" : "Products" }</span>
                      </div>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */ }
                      <div
                        className="mt-2"
                        onClick={ () => { onCloseCrossIcon() } }>
                        <span className='our-story-crossIcon'>&times;</span>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }

            { ( disabledMenu )
              ? [] :
              <div className={ ( showCollapsedNavbar )
                ? 'collapse navbar-collapse d-lg-flex justify-content-lg-end show'
                : 'collapse navbar-collapse d-lg-flex justify-content-lg-end' }
                id="navbarNav"
              >
                <ul className="navbar-nav nav-item__nav-item-mobile" ref={ dropdownRef }>
                  {
                    navigation.map( ( element, index ) => { // eslint-disable-line array-callback-return
                      if ( element.slice_type === "1st_level" ) {
                        // console.log('=========================')
                        // console.log('element.primary?.link_id', element.primary?.link_id)
                        // console.log('element.primary.nav_link.url', element.primary.nav_link.url)
                        // console.log('map function persID useState', persID)
                        // console.log('map function cookies.persID useState', cookies.persID)
                        return (
                          <li className="nav-item hover-effect" key={index}
                            onClick={() => openNewScreen(element)}
                            onMouseOver={() => setActiveDropdownMenu(null)}
                            style={(lang === 'en-us' && showBanner && showBannerCustomPageType[activeDocMeta?.type]) ? styles.navItem : {}}
                          >

                            { element.primary.nav_link.link_type === "Document" && <Link
                              to={ element.primary.nav_link.url }
                              target="_self"
                              title={ "White Claw " + ( element.primary.link_text ) }
                              onClick={ (event) => {
                                if ( element.primary.nav_link.url.includes( 'locator' ) ) {
                                  onBuyNowClick(event)
                                }
                              } }
                            >
                              { element.primary.link_text }
                            </Link> }

                            { element.primary.nav_link.link_type === "Web" && <a
                              href={
                                ( element.primary?.link_id === 'faq' && persID ) ||
                                  ( element.primary?.link_id === 'locator' && persID ) ?
                                  `${element.primary.nav_link.url.includes('?') ? element.primary.nav_link.url + '&' : element.primary.nav_link.url + '?'}persID=${persID}&adult=${cookies?.adult}&ag=${!cookies?.adult}` :
                                element.primary.nav_link.url}
                              rel='noopener noreferrer'
                              target="_blank"
                              title={ "White Claw " + ( element.primary.link_text ) }
                            >
                              { element.primary.link_text }
                            </a> }
                          </li>
                        )
                      } else if ( element.slice_type === "2nd_level" && ( element.primary.link_text !== "Our Story" && element.primary.link_text !== "Products" ) ) { // Secondary level will be added here fix needed.
                        return (
                          <li className="nav-item" key={index}
                          onClick={(e) => handleSecondLevelMenuClick( index ) }
                          onMouseOver={() => {
                            if(width >= 991 && hoverMenu===true){
                              setActiveDropdownMenu(index)
                            }
                          }}
                          style={(lang === 'en-us' && showBanner && (showBanner && showBannerCustomPageType[activeDocMeta?.type])) ?  styles.navItem : {}}
                          >
                            <button className="nav-item__dd"
                              onClick={ ( e ) => handleSecondLevelMenuClick( index ) }
                              onMouseOver={() => {
                                if(width >= 991 && hoverMenu===true){
                                  setActiveDropdownMenu(index)
                                }
                              }}
                            >
                              <span className="nav-item__dd-trigger active" >{ element.primary.link_text }</span>
                              <span>
                                {
                                  activeDropdownMenu === index ?
                                    <i className="arrow up"></i>
                                    : <i className="arrow down"></i>
                                }
                              </span>
                              { activeDropdownMenu !== null && activeDropdownMenu === index &&
                                <ul className="dropdown-menus">
                                  {
                                    pageData.body[ activeDropdownMenu ]?.items.map( ( childElm, childElmIndex ) => {
                                      return (
                                        <li key={ 'child' + childElmIndex } className='nav-item__second_level'>

                                          {childElm.third_level_link.url == "/shore-club-signup" ?
                                          <a
                                          href={ childElm.third_level_link.url }
                                          target="_self"
                                          title="White Claw"
                                          onClick={ () => setShowCollapsedNavbar( false ) }
                                        >
                                          { childElm.third_level_link_text}
                                        </a>:<Link
                                            to={ childElm.third_level_link.url }
                                            target="_self"
                                            title="White Claw"
                                            onClick={ () => setShowCollapsedNavbar( false ) }
                                          >
                                            { childElm.third_level_link_text}
                                          </Link>}
                                        </li>
                                      )
                                    } )
                                  }
                                </ul>
                              }
                            </button>
                            <FontAwesomeIcon icon={ faChevronRight } className="fa-solid nav-item__chevron_right" size="xl" />
                          </li>
                        )
                      } else if ( element.slice_type === "2nd_level" && element.primary.link_text === "Products" ) {
                        return (
                          <li key={ "child-products" + index } className="nav-item "
                            onClick={ ( e ) => {
                              handleSecondLevelMenuClick( index )
                            } }
                            onMouseOver={() => {
                              if(width >= 991 && hoverMenu===true ){
                                setActiveDropdownMenu(index)
                              }
                            }}
                            style={(lang === 'en-us' && showBanner && showBannerCustomPageType[activeDocMeta?.type]) ? styles.navItem :{}}
                          >
                            <button className="nav-item__dd"
                              onClick={ ( e ) => {
                                handleSecondLevelMenuClick( index )
                              }
                              }
                              onMouseOver={() => {
                                if(width >= 991 && hoverMenu===true){
                                  setActiveDropdownMenu(index)
                                }
                              }}
                            >
                              <span>{ element.primary.link_text }</span>
                              <span>
                                {
                                  activeDropdownMenu === index ?
                                    <i className="arrow up"></i>
                                    : <i className="arrow down"></i>
                                }
                              </span>
                            </button>
                            {
                              activeDropdownMenu === index &&
                              products && products.data && products.data.product_sections &&
                              <div className='sub-menu mega-menu mega-menu-column-4 product' >
                                <button id="product-prev-nav" className="product-nav-scroll-arrow product-nav-scroll-arrow--prev"
                                  onClick={ ( e ) => {
                                    productNavRef.current.scrollLeft = 0
                                    e.currentTarget.style.visibility = 'hidden'
                                  } }>
                                  <span className="visually-hidden">Prev</span>
                                  <FontAwesomeIcon icon={ faChevronLeft } className="fa-solid" size="2xl" />
                                </button>
                                <ul key={ 'product nav' } id={ 'product nav' } ref={productNavRef}  className='sub-menu mega-menu mega-menu-column-4 product nav-scroll' onScroll={ handleScrollProduct }>
                                  {
                                    products.data.product_sections.map( ( childElm, childElmIndex ) => {

                                      return (
                                        <div key={"product_sections"+childElmIndex}>
                                          {/* //only on mobile */ }
                                          { childElmIndex === 0 && width < 991 &&
                                            pageData.body[ activeDropdownMenu ]?.items.map( ( childElm, childElmIndex ) => {
                                              return (
                                                <div className='category-separator'>
                                                  <div key={ 'child' + childElmIndex } className='product-section-container '>
                                                    <div>
                                                      <Link
                                                        to={ childElm.third_level_link.url }
                                                        target="_self"
                                                        title="White Claw"
                                                        onClick={ () => {
                                                          setShowCollapsedNavbar( false )
                                                          document.body.classList.remove( 'overflow-hidden' )
                                                          document.documentElement.className = ''
                                                        } }
                                                      >
                                                        <img src={ childElm?.third_level_link_image?.url } alt={ childElm?.third_level_link_image?.alt } />
                                                      </Link>
                                                    </div>
                                                    <div>
                                                      <h4 className='product-category-title'>
                                                        <Link
                                                          to={ childElm.third_level_link.url }
                                                          target="_self"
                                                          title="White Claw"
                                                          onClick={ () => {
                                                            setShowCollapsedNavbar( false )
                                                            document.body.classList.remove( 'overflow-hidden' )
                                                            document.documentElement.className = ''
                                                          } }
                                                        >
                                                          { childElm.third_level_link_text }
                                                        </Link>
                                                      </h4>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            } ) }
                                          <li key={ 'child product' + childElmIndex } className={`list-item ${childElmIndex!==products.data.product_sections.length-1 ? 'product' : ''}`}>
                                            <div  className='item'>
                                              <h4 className='category-title'>
                                                {childElm.section_item.document.data.section_name}
                                              </h4>
                                              <div className="vertical-line"></div>
                                              <h4 className='category-title view-all-text'>
                                                <Link
                                                  to={childElm.section_item.document.data.nav_view_all_link.text}
                                                  target="_self"
                                                  title="View All"
                                                  onClick={ () => {
                                                    setShowCollapsedNavbar( false )
                                                    document.body.classList.remove( 'overflow-hidden' )
                                                    document.documentElement.className = ''
                                                  } }
                                                >
                                                  <div className='view-all-container'>{childElm.section_item.document.data.nav_view_all_text.text}</div>
                                                </Link>
                                              </h4>
                                            </div>
                                            <ul className="category-separator">
                                              {
                                                childElm.section_item.document.data.section_categories.map( ( subCategory, subCategoryIndex ) => {
                                                  return (
                                                    <li className='product-item-desktop' key={ 'child' + subCategoryIndex }>
                                                      <div key={ 'child' + subCategoryIndex } className='list-item product-item '>
                                                        <div>
                                                          <Link
                                                            to={ "/products#" + ( subCategory.product_category.document.data.category_name === "LEMONADE" ? "refrshr-lemonade" : subCategory.product_category.document.data.category_name.replace( ' ', '-' ).replace( '+', '-' ).toLowerCase() ) }
                                                            target="_self"
                                                            title="White Claw"
                                                            onClick={ () => {
                                                              setTimeout(() => {
                                                                setActiveDropdownMenu(false);
                                                              }, 10);
                                                              setShowCollapsedNavbar( false )
                                                              document.body.classList.remove( 'overflow-hidden' )
                                                              document.documentElement.className = ''
                                                            } }
                                                          >
                                                            <img src={ subCategory.product_category.document.data.navigation_image.url } alt={ subCategory.product_category.document.data.navigation_image.alt } className='product-section' />
                                                          </Link>
                                                        </div>
                                                        <div>
                                                          <h4 className='product-category-title' >
                                                            <Link
                                                              to={ "/products#" + ( subCategory.product_category.document.data.category_name === "LEMONADE" ? "refrshr-lemonade" : subCategory.product_category.document.data.category_name.replace( ' ', '-' ).replace( '+', '-' ).toLowerCase() ) }
                                                              target="_self"
                                                              title="White Claw"
                                                              onClick={ () => {
                                                                setShowCollapsedNavbar( false )
                                                                document.body.classList.remove( 'overflow-hidden' )
                                                                document.documentElement.className = ''
                                                              } }
                                                            >
                                                              {
                                                              subCategory.product_category.document.data.category_name.includes("+") ?
                                                                subCategory.product_category.document.data.category_name.replace(/\+/g, ' +')
                                                                :subCategory.product_category.document.data.category_name
                                                               }
                                                            </Link>
                                                          </h4>
                                                        </div>
                                                      </div>
                                                      { subCategory.product_category.document.data.new_tag.text !== "" &&
                                                        <div className='new-tag-container'>
                                                          <div className='new-tag-text'>
                                                            { subCategory.product_category.document.data.new_tag.text }
                                                          </div>
                                                        </div>
                                                      }
                                                    </li>
                                                  )
                                                } )
                                              }
                                            </ul>
                                          </li>
                                        </div>
                                      )
                                    } )
                                  }
                                  {/* //only on desktop */ }
                                  { width >= 991 &&
                                    <div className='nav-item__button ul-all-product-link'>
                                      <Link
                                        to={ "/products" }
                                        target="_self"
                                        title="VIEW ALL PRODUCTS"
                                        onClick={ () => {
                                          setTimeout(() => {
                                            setActiveDropdownMenu(false);
                                        }, 10);
                                        } }
                                      >
                                        { "VIEW ALL PRODUCTS" }
                                      </Link>
                                    </div>
                                  }


                                </ul>
                                <button id="product-next-nav" className="product-nav-scroll-arrow product-nav-scroll-arrow--next"
                                  style={ {
                                    visibility: productNavRef?.current &&
                                      ( productNavRef?.current?.scrollWidth > productNavRef?.current?.clientWidth &&
                                        productNavRef?.current?.scrollWidth - productNavRef?.current?.clientWidth !== Math.round( productNavRef?.current?.scrollLeft ) ) ? "visible" : "hidden"
                                  } }
                                  onClick={ ( e ) => {
                                    productNavRef.current.scrollLeft = productNavRef.current.scrollWidth
                                    e.currentTarget.style.visibility = 'hidden'
                                  } }>
                                  <span className="visually-hidden">Next</span>
                                  <FontAwesomeIcon icon={ faChevronRight } className="fa-solid" size="2xl" />
                                </button>
                              </div>


                            }

                            <FontAwesomeIcon icon={ faChevronRight } className="fa-solid nav-item__chevron_right" size="xl" />
                          </li>
                        )
                      }
                      else if ( element.slice_type === "2nd_level" && element.primary.link_text === "Our Story" ) {
                        return (
                          <li key={index} className="nav-item"
                          ref={ourStoryRef}
                          onClick={(e) =>
                            {
                            handleSecondLevelMenuClick(index)}
                          }
                          onMouseOver={() => {
                            if(width >= 991 && hoverMenu===true){
                              setActiveDropdownMenu(index)
                            }
                          }}
                          >
                            <button className="nav-item__dd"
                              onClick={ ( e ) => handleSecondLevelMenuClick( index ) }
                              onMouseOver={() => {
                                if(width >= 991 && hoverMenu===true){
                                  setActiveDropdownMenu(index)
                                }
                              }}
                            >
                              <span>{ element.primary.link_text }</span>
                              <span>
                                {
                                  activeDropdownMenu === index ?
                                    <i className="arrow up"></i>
                                    : <i className="arrow down"></i>
                                }
                              </span>
                            </button>
                            {
                              activeDropdownMenu !== null && activeDropdownMenu === index &&
                              <ul className='sub-menu mega-menu mega-menu-column-4 our-story'>
                                {
                                  pageData.body[ activeDropdownMenu ]?.items.map( ( childElm, childElmIndex ) => {
                                    return (
                                      <li key={ 'child-our-story' + childElmIndex } className='list-item'>
                                        { childElmIndex === 0 && <div className='our-story-mobile'></div> }
                                        <Link
                                          to={ childElm.third_level_link.url }
                                          target="_self"
                                          title="White Claw"
                                          onClick={ () => closeOurStoryNavBar() }
                                        >
                                          <img src={ childElm.third_level_link_image?.url } className='img-fluid' alt={ childElm.third_level_link_image?.alt } />
                                        </Link>
                                        <h4 className='title'>
                                          <Link
                                            to={ childElm.third_level_link.url }
                                            target="_self"
                                            title="White Claw"
                                            onClick={ () => closeOurStoryNavBar() }
                                          >
                                            { childElm.third_level_link_text }
                                          </Link>
                                        </h4>
                                      </li>
                                    )
                                  } )
                                }
                              </ul>
                            }
                            <FontAwesomeIcon icon={ faChevronRight } className="fa-solid nav-item__chevron_right" size="xl" />
                          </li>
                        )
                      }
                    } )
                  }
                </ul>

                <ul className="d-flex flex-row  navbar-nav navbar-nav--social nav-item__social_mobile_view"
                style={lang === 'en-us' && (showBanner & showBannerCustomPageType && showBannerCustomPageType[activeDocMeta?.type]) ? styles.socialMobileView : {}}
                >
                  <li className="nav-item text-center" style={(lang === 'en-us' && showBanner & showBannerCustomPageType[activeDocMeta?.type]) ? styles.navItem : {}}>
                    <a href="https://www.facebook.com/whiteclaw" target="_blank" rel='noopener noreferrer'
                      onClick={ () => onSocialIconClick( SOCIAL_PLATFORM.FACEBOOK ) }
                    >
                      <FontAwesomeIcon icon={ faSquareFacebook } className="fa-icons" />
                    </a>
                  </li>
                  <li className="nav-item text-center"
                  style={(lang === 'en-us' && showBanner & showBannerCustomPageType && showBannerCustomPageType[activeDocMeta?.type]) ? styles.navItem : {}}
                  >
                    <a href="https://www.instagram.com/whiteclaw" target="_blank" rel='noopener noreferrer'
                      onClick={ () => onSocialIconClick( SOCIAL_PLATFORM.INSTAGRAM ) }
                    >
                      <FontAwesomeIcon icon={ faSquareInstagram } className="fa-icons" />
                    </a>
                  </li>
                  <li className="nav-item text-center"
                  style={(lang === 'en-us' && showBanner & showBannerCustomPageType && showBannerCustomPageType[activeDocMeta?.type]) ? styles.navItem : {}}
                  >
                    <a href="https://twitter.com/whiteclaw" target="_blank" rel='noopener noreferrer'
                      onClick={ () => onSocialIconClick( SOCIAL_PLATFORM.TWITTER ) }
                    >
                      <FontAwesomeIcon icon={ faSquareXTwitter } className="fa-icons" />
                    </a>
                  </li>
                </ul>

                {/* Country Selector */ }
                <div className="country-selector-btn-wrapper"
                style={(lang === 'en-us' && showBanner & showBannerCustomPageType[activeDocMeta?.type]) ? styles.countrySelectorBottom : {}}
                >
                  <button className="country-selector-btn" onClick={ openCountrySelector } onKeyDown={ openCountrySelector }>
                    { countryData ?
                      <img src={ countryData } className="country__flag" alt="Globe Icon" />
                      : <img src={ globe } className="globe" alt="Globe Icon" /> }

                  </button>
                </div>
                <CountrySelector showCountrySelector={ showCountrySelector } closeCountrySelector={ closeCountrySelector } activeDocMeta={ lang } />
              </div>
            }

            {/* Menu Hamburger */ }
            { ( disabledMenu )
              ? [] :
              <button className={ ( showCollapsedNavbar )
                ? "navbar-toggler show"
                : "navbar-toggler"
              } onClick={ () => {
                //collapse navbar
                if ( showCollapsedNavbar ) {
                  //body not scrollable to scrollable
                  document.body.classList.remove( 'overflow-hidden' )
                  document.documentElement.className = ''
                } else {
                  //body scrollable to not scrollable
                  document.body.classList.add( 'overflow-hidden' )
                  document.documentElement.className = 'overflow-hidden'
                }
                setShowCollapsedNavbar( !showCollapsedNavbar )
              } }>
                <div className="hamburger vertical-center">
                  <div className="bar bar--1"></div>
                  <div className="bar bar--2"></div>
                  <div className="bar bar--3"></div>
                </div>
              </button>
            }
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header
