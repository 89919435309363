import React from 'react'

// Components
import Header from './Header'
import Footer from './Footer'
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import {useStaticQuery,graphql} from 'gatsby';
import AnnouncementBanner from './AnnouncementBanner';

// CSS
// import 'bootstrap/dist/css/bootstrap.min.css'
// import '../assets/css/styles.min.css'

const Layout404 = ({children, currentPage, customPage, activeDocMeta,persID}) => {
  // console.log('layout 404 activeDoc', activeDocMeta)

  const queryData=useStaticQuery(graphql`
    {
      prismicAnnouncementBanner {
      data {
      body {
        ... on PrismicAnnouncementBannerDataBodyBannerSlice {
          id
          items {
            banner_background
            banner_title {
              richText
              html
            }
            banner_title_font_size_desktop
            banner_title_font_size_mobile
            banner_title_colour
            cta_text_desktop {
              text
              html
            }
            cta_text_link_desktop {
              url
            }
            cta_text_mobile {
              text
              html
            }
            cta_text_link_mobile {
              url
            }
            show_specific_banner
          }
          primary {
            generic_page
            landing_page
            contact_us_page
            events_page
            locator_page
            microsite
            new_product_page
            product_details_page
            rules_page
            survey_page
            terms_and_conditions_page
            unsubscribe_page
            wyng_page
            wishpond_page
          }
        }
      }
      dismiss_banner
      show_banner
      sticky_banner
    }
  }
    }
    `)

  const {data}=useMergePrismicPreviewData(queryData)
  const pageData=data?.prismicAnnouncementBanner?.data
  const showBanner=data?.prismicAnnouncementBanner?.data?.show_banner;
  const showBannerCustomPageType=pageData?.body[0]?.primary;
  const isSticky=pageData?.sticky_banner;
  return(
    <>
      {/*AnnouncementBanner*/}
      {
        activeDocMeta.lang==="en-us"&&
          showBanner?
          (showBannerCustomPageType[activeDocMeta?.type]!==false||showBannerCustomPageType[activeDocMeta?.type]===undefined)?
            <AnnouncementBanner data={pageData} pageType={activeDocMeta?.type} currentPage={currentPage} />:null
          :null
      }
      {/* Header */}
      <Header customPage={(currentPage)? currentPage : null} activeDocMeta={activeDocMeta} persID={persID} isSticky={isSticky} showBannerCustomPageType={showBannerCustomPageType} showBanner={showBanner}/>
      {(currentPage)?  <main id={currentPage}>{children}</main>
      : <main>{children}</main>
      }
      {/* Footer */}
      <Footer activeDocMeta={activeDocMeta} persID={persID}/>
    </>
  )
}

export default Layout404