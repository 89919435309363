// core
import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { useLocation } from "@reach/router";
import axios from 'axios'

// components
import { Seo } from '../components/Seo'
import Layout from '../components/Layout'
import { SliceZone } from '@prismicio/react'
import {components} from "../slices/product-locator-page"
import { useConsent } from '../components/ConsentContext'

import {mikMakLocatorScript} from "../constants/mikMak";

// constant
import { PRODUCT_EARNS } from "../constants/productEans"
import { GOOGLE_MAP_API_URL } from '../constants/baseUrl'

// data-layer
import {
  setLocatorPageViewEvent,
  setRetailerSelectedEvent,
  setLocatorAddressSearchedEvent,
  setLocatorFlavorSelectedEvent,
  setGetDirectionsEvent
} from "../assets/js/data-layer"

const ProductLocatorPage = ({data}) => {
  const pageContent = data.prismicProductLocatorPage
  const siteData = data.site.siteMetadata
  const location = useLocation()

  const { meta_title, meta_description, social_card, body: slices, product_id: productId } = data.prismicProductLocatorPage.data

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const consentAccepted = useConsent()

  // push product Ids (GTINs) to mikmak dataLayer and added TAG
  useEffect(()=>{
    const script = document.createElement("script")
    script.type = 'text/javascript'
    script.innerText = `
      window.swnTagLoaded = false,
      window.swnDataLayer = { product_eans: "${productId}" }; 
      ${ mikMakLocatorScript }
    `
    document.body.appendChild(script)

    // data layer - mikmak events
    if(window.swnDataLayer){
      var selectedFlavor = ''
      var dropdownClicked = false
      window.swnDataLayer.widget_callback = async (data) => {
        if(data.action == 'showmap'){
          // method
        }else if(data.action === 'buy'){
          const supplier = data.store.name.toLowerCase()
          const flavor = data.product.name.toLowerCase()
          const getCategory = function(){
            for(const item in PRODUCT_EARNS){
              if(item === data.product.ean){
                return PRODUCT_EARNS[item].cat?PRODUCT_EARNS[item].cat:''
              }
            }
          }
          const category = getCategory()
          const size = data.product.pkg?data.product.pkg.toLowerCase():''
          const price = `${data.product.price}`
          const method = 'online'
          setRetailerSelectedEvent({
            'supplier': supplier,
            'flavor': flavor,
            'category': category,
            'size': size,
            'price': price,
            'method': method
          }, consentAccepted)
        }else if(data.action === 'clkRoute'){
          // retailer selected event
          const supplier = data.store.name.toLowerCase()
          setGetDirectionsEvent({
            'supplier': supplier,
            'pageURL': location.href
          }, consentAccepted)
        }else if(data.action === 'geoloc'){
          // locator address searched event
          const zipCode = data.location.zipCode
          if(zipCode){
              setLocatorAddressSearchedEvent  ({
                          'zc': data.location.zipCode,
                          'cty': data.location.city,
                          'st': data.location.region,
                          'ctry': data.location.country,
                          'addr': data.location.address,
                        }, consentAccepted)
            // const locationData = await getAddressFromZipCode(zipCode)
            // setLocatorAddressSearchedEvent(locationData, consentAccepted)
          }
        }else if(data.action === 'ddnChange'){
          dropdownClicked = true
        }else if(data.action === "clk" && dropdownClicked){
          // locator flavor selected event
          if(selectedFlavor !== data.product.name){
            const flavor = data.product.name.toLowerCase()
            const getCategory = function(){
              for(const item in PRODUCT_EARNS){
                if(item === data.product.ean){
                  return PRODUCT_EARNS[item].cat?PRODUCT_EARNS[item].cat:''
                }
              }
            }
            const category = getCategory()
            const size = data.product.pkg?data.product.pkg.toLowerCase():''
            const method = ''

            setLocatorFlavorSelectedEvent({
              'flavor': flavor,
              'category': category,
              'size': size,
              'method': method
            }, consentAccepted)
          }
          dropdownClicked = false
        }

        selectedFlavor = data.product.name
      }
    }

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)
    }
  },[])

  // data layer - locator pageview event
  useEffect(() => {
    const dlData = {
      title: meta_title.text || siteData.title,
      url: location.href,
      path: location.pathname,
      referrer: (sessionStorage.getItem('previous_page_url'))?
        sessionStorage.getItem('previous_page_url') : location.pathname,
      language: lang
    }

    setLocatorPageViewEvent(dlData, consentAccepted)
  }, [pageContent, consentAccepted])

  const getAddressFromZipCode = async (zipcode) => {
    try{

      const response = await axios({
        url: GOOGLE_MAP_API_URL('AIzaSyBdKe9lOFpOiN8Wu51RzCnIXpiV3bf61J8', zipcode),
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      })

      if(response.status === 200){
        const result = response.data.results[0]
        if(!result){
          throw new Error('No address found.')
        }

        return result.address_components.reduce((cus, component) => {
          const types = component.types

          if(types.includes('postal_code')){
            cus.zc = component.long_name
          }else if(types.includes('locality')){
            cus.cty = component.long_name
          }else if(types.includes('country')){
            cus.ctry = component.short_name
          }else if(types.includes('administrative_area_level_1')){
            cus.st = component.long_name
          }
          return cus
        }, { addr: result.formatted_address })
      }else{
        throw new Error(`Error: ${response.status}, ${response.statusText}`)
      }
    }catch(err){
      console.error(err.message)
      return null
    }
  }

  return (
      <Layout currentPage='prod-locator' activeDocMeta={activeDoc}  setPreviousPage={false}>
        <Seo
            title={meta_title?.text}
            description={ meta_description?.text}
            image={social_card?.url}
            activeDocMeta={activeDoc}
            currentPage={"locator"}
        />
        <SliceZone components={components} slices={slices} />
      </Layout>
  );
}

export const query = graphql`
query ProductLocatorPageQuery($uid: String, $id: String, $lang: String){
    prismicProductLocatorPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
        _previewable
        url
        uid
        type
        id
        lang
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
            product_id
            social_card {
                url 
            }
            meta_description {
                text
            }
            meta_title {
                text
            }
            body {
               ... on PrismicProductLocatorPageDataBodyLocatorHero {
                  id
                  slice_type
                  primary {
                     banner {
                        alt
                        url
                     }
                     title {
                        text
                     }
                  }
               }
               ... on PrismicProductLocatorPageDataBodyLocatorSection {
                  id
                  slice_type
               }
           }
        }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
}
`
export default withPrismicPreview(ProductLocatorPage)