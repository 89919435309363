import React, { useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import IframeResizer from 'iframe-resizer-react';

const BazaarVoiceProductPicker=({ slice }) => {
 const stringToArray = (string)=>{
  let array = string
  if (string.indexOf(',') !== -1) {
     array = string.split(',');
  }
  return array
}
  const preventClose = slice.primary.prevent_close ? 'true' : 'false';
  const toggleCategoryProductFamily = slice.primary.toggle_category_product_family;
  const categoryId = toggleCategoryProductFamily == true ? slice.primary.category_id:null ;
  const familyProductId = toggleCategoryProductFamily == false ? slice.primary.family_product_id?stringToArray(slice.primary.family_product_id):null : null;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apps.bazaarvoice.com/deployments/white_claw/main_site/production/en_US/bv.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      document.body.style.overflow = 'auto';
    };
  },[]);
  const ref = useRef();

  return (
    <section id="product-picker-section">
      <IframeResizer
        forwardRef={ref}
        src="/iframes/bv-product-picker-widget.html"
        id="bv-product-picker"
        style={{ width: '100%'}}
        heightCalculationMethod="bodyScroll"
        data-bv-category-id={categoryId}
        data-bv-family-product-id={familyProductId}
        data-bv-inline="true"
        data-bv-prevent-close={preventClose}
        scrolling = "yes"
        onLoad={() => {
          const iframe = document.getElementById('bv-product-picker');
          const data = {
            categoryId: categoryId?categoryId:undefined,
            familyProductId: familyProductId?familyProductId:undefined,
            preventClose:preventClose?preventClose:''
          };
          iframe.contentWindow.postMessage(data, '*')
        }}
      />
    </section>
  );
};

export default BazaarVoiceProductPicker;

export const fragment = graphql`
  fragment PageDataBodyBazaarVoiceProductPicker on PrismicGenericPageDataBodyBazaarVoiceProductPicker {
    id
    primary {
        category_id
        family_product_id
        prevent_close
        toggle_category_product_family
    }
    slice_label
    slice_type
  }
`;