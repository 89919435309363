import React, {useEffect, useState, useRef} from 'react'
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

// components
import ImgWithFallback from "./ImgWithFallback";
import  CustomLink  from "./CustomLink";

// data-layer
import { setLearnMoreClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

//assets
import useWindowSize from "../hooks/useWindowSize";

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

const HomeHero = ({pageQuery}) => {
  const { width } = useWindowSize()
  const location = useLocation()
  const playerRef = useRef()
  const consentAccepted = useConsent()
  const [swiper, setSwiper] = useState(null);
  const containerRef = useRef(null);

  const handleMouseEnter = () => {
    swiper.autoplay.stop();
  };

  const handleMouseLeave = () => {
    swiper.autoplay.start();
  };

  const PAGE_KEY = {
    VODKA_SODA_LAUNCH : "vodka-soda-launch",
    JB_SMOOVE: "jb-smoove",
    THE_DIFFERENCE_IS_CLEAR: "the-difference-is-clear",
    CLAWBACK: "clawback-summer",
    CAROUSEL: "carousel"
  }

  const pageKey = pageQuery.key
  const carousel = PAGE_KEY.CAROUSEL ? pageQuery.carousel : undefined
  const nutritionItems = pageQuery.nutrition_info

  const button = {
    href: pageQuery.button.url || '',
    text: pageQuery.button_text.text || ''
  }
  // console.log('home-hero', pageQuery)
  // console.log('carousel length', carousel.length)

  const bg = {
    imageMobile: pageQuery.banner_background_mobile.url,
    imageScreen: pageQuery.banner_background.url,
    color: pageQuery.background_color,
  }

  const contentImage = {
    png: pageQuery.content_image.url,
    alt: pageQuery.content_image.alt
  }

  const contentLogo = {
    png: pageQuery.content_logo.url || '',
    alt: pageQuery.content_logo.alt || ''
  }

  const video = {
    src: pageQuery.video_src.url || '',
    mobileSrc: pageQuery.video_mobile_src.url || ''
  }

  const textContent = {
    title: pageQuery.title.text || '',
    subTitle: pageQuery.subtitle.text || ''
  }

  const navigation = {
    nextEl: '.hero-section__carousel-arrow--next',
    prevEl: '.hero-section__carousel-arrow--prev'
  }

  useEffect(()=>{
    if (!playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current.play()
    });

  },[])

  return (
  <>
    { pageKey === PAGE_KEY.CAROUSEL && carousel?.length && carousel[0].carousel_link.url &&
    <div
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      >
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation={navigation}
        pagination={{ clickable: true }}
        initialSlide={0}
        speed={500}
        slidesPerView={1}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true
        }}
        loop={true}
        onSwiper={setSwiper}
        className='hero-section__carousel'
        >
          {
            carousel.map((item, index) => {
              // console.log('carousel item', item)
              return (
                <SwiperSlide key={index}>
                  <CustomLink
                  to={item.carousel_link.url}
                  title={item.carousel_link_title}
                  onClick={()=> {
                    const dl = {
                      page_name: item.carousel_link_title,
                      referrer: location.pathname,
                      name: 'learn more',
                      url: item.carousel_link.url,
                    }
                    setLearnMoreClickedEvent(dl, consentAccepted)
                  } }
                  >
                    <ImgWithFallback
                      classNameImg='hero-section__carousel-img'
                      classNamePicture='hero-section__carousel-pct'
                      src={width < 768 ? item.carousel_slide_mobile.url : item.carousel_slide_desktop.url}
                      alt={width < 768 ? item.carousel_slide_mobile.alt : item.carousel_slide_desktop.alt}
                    />
                  </CustomLink>
                </SwiperSlide>
              )
            })
          }
          <button className="hero-section__carousel-arrow hero-section__carousel-arrow--prev">
            <span className="visually-hidden">Prev</span>
            <FontAwesomeIcon icon={faChevronLeft} className="fa-solid" size="2xl" />
          </button>
          <button className="hero-section__carousel-arrow hero-section__carousel-arrow--next">
            <span className="visually-hidden">Next</span>
            <FontAwesomeIcon icon={faChevronRight} className="fa-solid" size="2xl" />
          </button>
      </Swiper>
    </div>
    }

    { pageKey === PAGE_KEY.VODKA_SODA_LAUNCH && <section
        className={`position-relative hero-section hero-section__${pageKey} animation-element-container bounce-up py-0`}
        style={{backgroundImage: `${ width < 768 ? `url(${bg.imageMobile})` : `url(${bg.imageScreen})`}`, backgroundColor: `${bg.color || '#000000'} `}}>
      <div className="hero-container">
        <div className="row hero-container-row">
          <div className="col-img col-12 col-md-4 p-0">

          </div>
          <div className="col-12 col-md-8 col-content">
            < ImgWithFallback
                classNameImg='hero-section__content-img'
                src={contentImage.png}
                alt={contentImage.alt}
                fallbackSrc={contentImage.png}
            />
            {width >= 768  && <Link
                to={button.href}
                target="_blank"
                title={button.text}
                className="button-black hero-section__button"
            >
              {button.text}
            </Link>}
            {nutritionItems && <ul className="row nutrition-info">
              {nutritionItems.map(({nutrition_item}) => {
                return <li key={nutrition_item.richText[0].text}>{nutrition_item.richText[0].text}</li>
              })}
            </ul>}
          </div>
        </div>
      </div>
      {width <= 767  && <Link
          to={button.href || ''}
          target="_blank"
          title={button.text}
          className="button-black hero-section__button"
      >
        {button.text}
      </Link>}
    </section> }

    { pageKey === PAGE_KEY.JB_SMOOVE && <section
        className={`position-relative hero-section hero-section__${pageKey} animation-element-container bounce-up py-0`}
        style={{backgroundImage: `${ width < 768 ? `url(${bg.imageMobile})` : `url(${bg.imageScreen})`}`, backgroundColor: `${bg.color || '#ffffff'} `}}>
      <div className="hero-container">
        <div className="row hero-container-row">
          <div className="col-12 col-md-7 col-content">
            < ImgWithFallback
                classNameImg='hero-section__content-img'
                src={contentImage.png}
                alt={contentImage.alt}
                fallbackSrc={contentImage.png}
            />
            {width >= 768  && <Link
                to={button.href}
                target="_blank"
                title={button.text}
                className='button-white hero-section__button'
            >
              {button.text}
            </Link>}
          </div>
          <div className="col-img col-12 col-md-5 p-0">
          </div>
        </div>
      </div>
      {width <= 767  && <Link
          to={button.href || ''}
          target="_blank"
          title={button.text}
          className="button-black hero-section__button"
      >
        {button.text}
      </Link>}
    </section> }

    { pageKey === PAGE_KEY.THE_DIFFERENCE_IS_CLEAR && <section className='video-hero-section'>
      <a href={button.href}>
      <video className='video-hero-section__video'
              ref={playerRef}
              src={`${ width <= 768 ? video.mobileSrc : video.src}`}
              width="100%"
              height="100%"
              playsInline
              autoPlay
              muted
              loop
              poster=""/>
      </a>
      <div className='helper-bottom'></div>
      <div className='helper-right'></div>
      <CustomLink
          to={button.href}
          title={button.text}
          className="button-white video-hero-section__button"
          onClick={()=> {
            const dl = {
              url: button.href,
              referrer: location.pathname,
              name: button.text.toLowerCase(),
              page_name:location.pathname.split("/").pop()
            }

            setLearnMoreClickedEvent(dl, consentAccepted)
          } }
      >
        {button.text}
      </CustomLink>
    </section> }

    { pageKey === PAGE_KEY.CLAWBACK && <section
        className={`position-relative hero-section hero-section__${pageKey} animation-element-container bounce-up py-0`}
        style={{backgroundImage: `${ width < 768 ? `url(${bg.imageMobile})` : `url(${bg.imageScreen})`}`, backgroundColor: `${bg.color || '#000000'} `}}>
      <div className='hero-section__pill'>
          <div className='hero-section__pill-content'>
            <ImgWithFallback
              classNamePicture='hero-section__content-logo'
              src={contentLogo.png}
              alt={contentLogo.alt}
              fallbackSrc={contentLogo.png}
            />
            <h1 className='hero-section__pill-title'>{textContent.title}</h1>
            <p className='hero-section__pill-subtitle'>{textContent.subTitle}</p>
            {width > 767 &&
              <Link
                to={button.href}
                target="_blank"
                title={button.text}
                className="cb-button"
              >
                {button.text}
              </Link>
            }
          </div>
          <div className='hero-section__pill-bg'>
            <ImgWithFallback
              classNamePicture='hero-section__content-img'
              src={contentImage.png}
              alt={contentImage.alt}
              fallbackSrc={contentImage.png}
            />
          </div>
        </div>
      {width <= 767  && <Link
          to={button.href || ''}
          target="_blank"
          title={button.text}
          className="button-black hero-section__button"
      >
        {button.text}
      </Link>}
    </section> }
  </>
  )
}

export default HomeHero