import React, { useState } from 'react'
import { useLocation } from "@reach/router";
import { PrismicRichText } from "@prismicio/react";
import {graphql} from "gatsby";
import CustomLink from "../../components/CustomLink";

// data-layer
import { setLearnMoreClickedEvent } from "../../assets/js/data-layer";



export const NewProductSection = ({ slice }) => {
  const location = useLocation()
  const productCategory = {
    vodka: slice.primary.product_category === 'vodka',
    seltzer: slice.primary.product_category === 'seltzer',
    vp: slice.primary.product_category === 'variety-pack'
  }
  const [isBtnHovered, setIsBtnHovered] = useState(false)
  // console.log('new product section', slice)

  const rtbText = slice.primary.rtb_text?.richText.map((item)=>{ return item.text})
  const isrtbTextInAccentColor = slice.primary.rtb_text?.richText.some((item)=>{ return item.spans[0]?.type === 'strong'})
  const rtbSubText = slice.primary.rtb_subtext?.richText.map((item)=>{ return item.text})
  const description = slice.primary.content_description.richText

  const button = {
    href: slice.primary.button_link?.url || '',
    text: slice.primary.button_text?.text || ''
  }

  const bgImageUrl = slice.primary?.product_image?.url || ''
  const bgContentUrl = slice.primary?.content_background_image?.url || ''

  const mainHeadingColor = slice.primary.color
  const accentTextColor = slice.primary.accent_color
  const textColor = slice.primary.text_color? slice.primary.text_color : '#000000'
  const buttonColor = slice.primary.button_color
  const buttonHoverTextcolor = slice.primary.button_hover_text_color

  const title = slice.primary.title?.richText
  const subtitle = slice.primary.subtitle?.richText

  const handleMouseEnter = () => {
    setIsBtnHovered(true)
  }

  const handleMouseLeave = () => {
    setIsBtnHovered(false)
  }

  let buttonStyle = {}
  if(buttonColor){
    buttonStyle = (isBtnHovered)? {backgroundColor: `${buttonColor}`, color: `${buttonHoverTextcolor? buttonHoverTextcolor : '#000000'}`, borderColor: `${buttonColor}`} : {color: `${buttonColor}`, borderColor: `${buttonColor}`}
  }

  const hasDescription = description?.length !== 0 && description[0]?.text !== "" && description[0] != null

  return <section id= "new-products" className="new">
    <div className={`row mx-0 ${slice.primary.reverse ? 'flex-row-reverse' : ''}`}>
      <div className="col-lg-6 p-0 new__product-image" style={{ backgroundImage: `url(${ bgImageUrl })`}}></div>
      <div className="new__col-content col-lg" style={{ backgroundImage: `url(${ bgContentUrl })`}}>
        {/* rtb  seltzer */}
        { productCategory.seltzer && <div className="d-flex justify-content-center">
          <h3 className="new__rtb-header">
            { rtbText?.length !== 0 && rtbText.map((row, index) => {
              return  <span key={row} className='text-center new__rtb-header-wrap'>
                      <span className="new__rtb-header-text" style={{color: `${isrtbTextInAccentColor ? accentTextColor : mainHeadingColor}`}}>{row}</span>
                {rtbSubText[index] && <span className='new__rtb-header-subtext' style={{ color: `${textColor}` }}>{rtbSubText[index]}</span>}
                      </span>
            })}
          </h3>
        </div> }

        {/* rtb  variety-pack */}
        { productCategory.vp && <div className="d-flex justify-content-center">
          <h3 className="new__rtb-header new__rtb-header--variety-pack">
            { rtbText?.length !== 0 && rtbText.map((row, index) => {
              return  <span key={row} className='text-center new__rtb-header-wrap'>
                        <span className="new__rtb-header-text new__rtb-header-text--variety-pack" style={{color: `${isrtbTextInAccentColor ? accentTextColor : mainHeadingColor}`}}>{row}</span>
                      </span>
            })}
          </h3>
        </div> }
        <div className={`new-product__content${!hasDescription ? ' new-product__content--no-description' : ''} text-center`}>
          {/* title */}
          <h1 className={`new__title new__title--${slice.primary.product_category}`}> { title.map((el, index) => {
            return <span
                key={`${el.type}-${index}`}
                style={{ color: `${el.spans[0]?.type === 'strong'? accentTextColor : mainHeadingColor}`, fontSize: `${el.type === 'heading1'? 'inherit': '0.65em'}`}}>
                <PrismicRichText field={[el]}/>
            </span> })}
          </h1>

          {/* underline and subtitle */}
          { subtitle?.length !== 0 && subtitle[0]?.text !== "" && subtitle[0] != null && <div className='d-inline-block'>
            { subtitle?.length !== 0 && <span className='new__underline-dark'></span> }
            { subtitle?.length !== 0 && <h2 className='new__subtitle'> { subtitle.map((el, index) => { return <span key={`${el.type}-${index}`} style={{color: `${el.spans[0]?.type === 'strong'? accentTextColor : mainHeadingColor}`}} className='new__subtitle-item'>
              <PrismicRichText field={[el]}/> </span>})} </h2> }
          </div>
          }

          {/* rtb vodka */}
          { productCategory.vodka && <div className="d-flex justify-content-center mt-3">
            <h3 className="new__rtb-header new__rtb-header--vodka pb-0">
              {rtbText?.length !== 0 && rtbText.map((row) => {
                return <span key={row} className='text-center new__rtb-header-wrap'>
                        <span className="new__rtb-header-text new__rtb-header-text--vodka"
                              style={{color: `${ isrtbTextInAccentColor ? accentTextColor : mainHeadingColor}`}}>{row}</span>
                        </span>
              })}
            </h3>
          </div> }

          {hasDescription && description.map((p) => {
            return <span key={p.text} className={`mt-1 mt-lg-0 new__product-description new__product-description--${slice.primary.product_category}`} style={{color: `${textColor}`}}>
              <PrismicRichText field={[p]}/>
            </span>
          })}

          {/* button */}
          <div className="buttons-wrap">
            { button.href && <CustomLink
                to={button.href}
                title={button.text}
                className="button-white new__button"
                style={buttonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  const dl = {
                    url: button.href,
                    referrer: location.pathname,
                    name: button.text.toLowerCase()
                  }
                  setLearnMoreClickedEvent(dl)
                }
                }>
              {button.text}
            </CustomLink>
            }
          </div>
        </div>
      </div>
    </div>
  </section>
}

export const fragment = graphql`
  fragment PageDataBodyNewProductSection on PrismicGenericPageDataBodyNewProductSection {
          id
          primary {
            accent_color
            button_color
            button_hover_text_color
            color
            content_background_image {
              alt
              url
            }
            product_category
            content_description {
              html
              richText
              text
            }
            product_image {
              alt
              url
            }
            reverse
            rtb_subtext {
              html
              richText
              text
            }
            rtb_text {
              html
              text
              richText
            }
            text_color
            subtitle {
              html
              richText
              text
            }
            title {
              html
              richText
              text
            }
            button_link {
              url
            }
            button_text {
              text
              richText
            }
          }
          slice_label
          slice_type
          }`



