import { Hero } from "./Hero"
import { YouTubeVideoSection } from "./YouTubeVideoSection"
import { FullWidthTextWithButton } from "./FullWidthTextWithButton"
import { SplitBanner } from "./SplitBanner"
import { FindNearYou } from "./FindNearYou"
import { ContentPanel } from "./ContentPanel"
import { FullWidthSlider } from './FullWidthSlider'
import { AboutProduct } from "./AboutProduct"
import { ProductRange } from "./ProductRange"
import { ProductTastingNotes } from "./ProductTastingNotes"
import { MasonryGallery } from "./MasonryGallery"
import { CrossLink } from "./CrossLink"
import { TabbedRange } from "./TabbedRange"
import { OvalImage } from "./OvalImage"
import { InstagramGallery } from "../InstagramGallery";
import { PolaroidPhotoGallery } from "./PolaroidPhotoGallery"
import { ClickMoreBanner } from "./ClickMoreBanner"
import { Iframe } from "./Iframe"
import { ProductCarousel } from "./ProductCarousel"
import { Membership } from "./Membership"
import { WyngForm } from './WyngForm'
import { FullWidthSectionDivider } from "./FullWidthSectionDivider"
import { TickerTape } from "./TickerTape"
import { Tiles } from "./Tiles"
import { Event } from "./Events"
import { AccordianComponent } from "./AccordianComponent"
import { BaazarVoiceReviewButtonComponent } from "./BaazarVoiceReviewButtonComponent"
import BazaarVoiceProductPicker from "./BazaarVoiceProductPicker"
import { CustomHtmlContent } from "./CustomHtmlContent"
import { NewProductSection } from "./NewProductSection"


export const components = {
  hero: Hero,
  youtube_video_section: YouTubeVideoSection,
  fullwidth_text_with_button: FullWidthTextWithButton,
  split_banner: SplitBanner,
  find_near_you: FindNearYou,
  content_panel: ContentPanel,
  fullwidth_slider: FullWidthSlider,
  about_product: AboutProduct,
  product_range: ProductRange,
  product_tasting_notes: ProductTastingNotes,
  crosslink: CrossLink,
  tabbed_range: TabbedRange,
  masonry_gallery: MasonryGallery,
  oval_image: OvalImage,
  instagram_gallery: InstagramGallery,
  polaroid_photo_gallery: PolaroidPhotoGallery,
  click_more_banner: ClickMoreBanner,
  iframe: Iframe,
  product_carousel: ProductCarousel,
  membership: Membership,
  wyng_form: WyngForm,
  _full_width_section_divider:FullWidthSectionDivider,
  ticker_tape:TickerTape,
  tile:Tiles,
  event:Event,
  accordion:AccordianComponent,
  baazar_voice_review_button:BaazarVoiceReviewButtonComponent,
  bazaar_voice_product_picker:BazaarVoiceProductPicker,
  custom_html_content:CustomHtmlContent,
  new_product_section: NewProductSection
}