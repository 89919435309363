import React from 'react'
import { useLocation } from "@reach/router";

//components
import ImgWithFallback from "../../components/ImgWithFallback";
import CustomLink from "../../components/CustomLink";

// data-layer
import { setBuyNowClickedEvent, setLearnMoreClickedEvent } from "../../assets/js/data-layer";
import { useConsent } from '../../components/ConsentContext'

// constants
import { LANG } from "../../constants/languages";

export const PromoModule = ( { slice,isOurStory,activeDocMeta} ) => {
  const location = useLocation()
  const promoItems = slice.items
  // console.log(slice)
  const { title } = slice.primary
  const consentAccepted = useConsent()
  const { lang } = activeDocMeta ? activeDocMeta : { lang: LANG.EN }
  if ( lang == LANG.EN ) {
    return(
      <section className='promo-module pb-5'>
          <div className='promo-module__content'>
            <h4 className='promo-module__title'>
              {title.text}
            </h4>
            <div className={isOurStory ? 'row promo-module__cards promo-module__promo-wrapper' : 'row promo-module__cards'}>
            {promoItems.map(({ image,
                               subtitle,
                               button_link,
                               button_text}) => {
              return <div key={subtitle.text} className='col-lg-4 col-sm-12 text-center'>
                <CustomLink to={ button_link.url || '' } className={"content-panel__custom_link_hover"}>
                < ImgWithFallback
                  classNameImg='w-100 promo-module__image'
                  src={ image.url }
                  alt={ image.alt }
                  fallbackSrc={ image.url }
                />
                <div className='promo-module__content-wrap'>
                <h5 className={isOurStory ? 'promo-module__subtitle-desktop-view' : 'promo-module__subtitle-common'}>
                    {isOurStory ? subtitle.text.toLowerCase().replace(/\u2028/g, " ") : subtitle.text}</h5>
                  <CustomLink
                    to={ button_link.url || '' }
                    title={ subtitle.text }
                    className="button-white promo-module__button"
                    onClick={ () => {

                      if ( button_link.url.includes( 'locator' ) &&
                        button_link.link_type === "Web" ) {
                        setBuyNowClickedEvent( { flavor: '', category: '' }, consentAccepted )
                      }

                      if ( button_link.link_type === 'Document' ) {
                        const dl = {
                          url: button_link.url,
                          referrer: location.pathname,
                          name: button_text.text.toLowerCase(),
                          page_name:location.pathname.split("/").pop()
                        }
                        setLearnMoreClickedEvent( dl, consentAccepted )
                      }
                    } }
                  >
                    { button_text.text }
                  </CustomLink>
                </div>
                </CustomLink>
              </div>
            } ) }
          </div>
          {
            isOurStory ?
              <div className='row'>
                { promoItems.map( ( { image,
                  subtitle,
                  button_link,
                  button_text } ) => {
                  return (
                    <div key={ subtitle.text } className='col-sm-12 promo-module__promo-module-our-story'>
                      <img
                        className='promo-module__promo-image'
                        src={ image.url }
                        alt={ image.alt } />
                      <div
                        className='promo-module__content-wrap_es'
                      >
                        <h5 className='promo-module__subtitle text-capitalize'>
                        { isOurStory ? subtitle.text.toLowerCase() : subtitle.text }
                        </h5>
                        <CustomLink
                          to={ button_link.url || '' }
                          title={ subtitle.text }
                          className="promo-module__promo-module-custom-link"
                          onClick={ () => {

                            if ( button_link.url.includes( 'locator' ) &&
                              button_link.link_type === "Web" ) {
                              setBuyNowClickedEvent( { flavor: '', category: '' }, consentAccepted )
                            }

                            if ( button_link.link_type === 'Document' ) {
                              const dl = {
                                url: button_link.url,
                                referrer: location.pathname,
                                name: button_text.text.toLowerCase(),
                                page_name:location.pathname.split("/").pop()
                              }
                              setLearnMoreClickedEvent( dl, consentAccepted )
                            }
                          } }
                        >
                          { button_text.text }
                        </CustomLink>
                      </div>
                    </div>
                  )
                } ) }
              </div>
              : null
          }
        </div>
      </section>
    )
  }
  else {
    return (
      <section className='promo-module pb-5'>
          <div className='promo-module__content'>
            <h4 className='promo-module__title'>
              {title.text}
            </h4>
            <div className='row promo-module__cards'>
            {promoItems.map(({ image,
                               subtitle,
                               button_link,
                               button_text}) => {
              return <div key={subtitle.text} className='col-lg-4 col-sm-12 text-center'>
                < ImgWithFallback
                  classNameImg='w-100 promo-module__image'
                  src={image.url}
                  alt={image.alt}
                  fallbackSrc={image.url}
                />
                <div className='promo-module__content-wrap'>
                  <h5 className='promo-module__subtitle'>
                    {subtitle.text}
                  </h5>
                  <CustomLink
                      to={button_link.url || ''}
                      title={subtitle.text}
                      className="button-white promo-module__button"
                      target="_self"
                      onClick={()=>{

                        if( button_link.url.includes('locator') &&
                            button_link.link_type === "Web") {
                          setBuyNowClickedEvent({ flavor: '', category: '' }, consentAccepted)
                        }

                        if(button_link.link_type === 'Document') {
                          const dl = {
                            url: button_link.url,
                            referrer: location.pathname,
                            name: button_text.text.toLowerCase(),
                            page_name:location.pathname.split("/").pop()
                          }
                          setLearnMoreClickedEvent(dl, consentAccepted)
                        }
                      }}
                  >
                    {button_text.text}
                  </CustomLink>
                </div>
              </div>
            })}
            </div>
          </div>
      </section>
  )
  }
}