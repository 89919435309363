import React from 'react'
import { useLocation } from "@reach/router";

//components
import ImgWithFallback from "../../components/ImgWithFallback";

//assets
import useWindowSize from "../../hooks/useWindowSize";

// data-layer
import { setBuyNowClickedEvent, setLearnMoreClickedEvent } from "../../assets/js/data-layer";
import { useConsent } from '../../components/ConsentContext'

export const PromoModule = ({ slice }) => {
  const { width } = useWindowSize()
  const location = useLocation()
  const consentAccepted = useConsent()

  const promoItems = slice.items
  const {title} = slice.primary

  // console.log(slice)

  return (
      <section className='promo-panel pb-5'>
        <div className='promo-panel__content'>
          <h4 className='promo-panel__title'>
            {title.text}
          </h4>
          <div className='row promo-panel__cards'>
            {promoItems.length !== 0 && promoItems.map(({ image,
                               subtitle,
                               button_link,
                               button_text}) => {
              return <div key={subtitle.text} className='col-md-4 col-sm-12 promo-panel__card'>
                < ImgWithFallback
                    classNameImg='promo-panel__image'
                    src={image.url}
                    alt={image.alt}
                    fallbackSrc={image.url}
                />
                <div className='promo-panel__content-wrap'>
                  <h5 className='promo-panel__subtitle'>
                    {subtitle.text}
                  </h5>
                  <a
                      href={button_link.url || ''}
                      title={subtitle.text}
                      className={ width < 768 ? 'promo-panel__button-mobile' : 'button-white promo-panel__button'}
                      target="_self"
                      onClick={()=>{

                        if( button_link.url.includes('locator')) {
                          setBuyNowClickedEvent({ flavor: '', category: '' }, consentAccepted)
                        }

                        if(button_link.link_type === 'Document') {
                          const dl = {
                            url: button_link.url,
                            referrer: location.pathname,
                            name: button_text.text.toLowerCase(),
                            page_name:location.pathname.split("/").pop()
                          }
                          setLearnMoreClickedEvent(dl, consentAccepted)
                        }
                      }}
                  >
                    {button_text.text}
                  </a>
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
  )
}