// core
import React from 'react'
import {graphql} from "gatsby";
export const CustomHtmlContent = ({slice}) => {

  const sectionId = slice.primary.section_id?.text || slice.id
  let rawHtml = slice.primary.raw_html.text; 
  const customCSS = slice.primary.custom_css_for_slice;
  const parseCustomCSS = (css) => {
    const cssObject = {};
    if (css) {
      const rules = css.split(';');
      rules.forEach(rule => {
        const [property, value] = rule.split(':').map(item => item.trim());
        if (property && value) {
          cssObject[property] = value;
        }
      });
    }
    return cssObject;
  };

  return (
      <section  className='custom-container' style={{ ...parseCustomCSS(customCSS) }}  id={`custom-html-content-${sectionId}`}>
            <div 
                className="custom-content" 
                dangerouslySetInnerHTML={{ __html: rawHtml }}
            />
      </section>
  )

}

export const fragment = graphql`
  fragment PageDataBodyCustomHtmlContent on PrismicGenericPageDataBodyCustomHtmlContent  {
    id
     primary {
            custom_css_for_slice
            raw_html {
              html
              raw
              richText
              text
            }
          }
  }`