import React,{useEffect,useState} from 'react'
import {Swiper,SwiperSlide} from "swiper/react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {faXmark} from '@fortawesome/free-solid-svg-icons'

// import Swiper core and required modules
import {Navigation,Autoplay} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import CustomLink from './CustomLink';
import useWindowSize from '../hooks/useWindowSize';
import {useCookies} from 'react-cookie';
import {PrismicRichText} from '@prismicio/react';
import {useConsent} from './ConsentContext';
import {onBannerImpressionEvent, onBannerInteractionEvent, onCloseButtonEvent} from '../assets/js/data-layer';
import {getFontSize} from '../assets/js';

const AnnouncementBanner=({data,pageType,currentPage}) => {
  const [swiper,setSwiper]=useState(null);
  const [scrollBanner,setScrollBanner]=useState(false)
  const [cookie,setCookie]=useCookies();
  const [dismissBanner,setDismissBanner]=useState(data?.dismiss_banner);
  const {width}=useWindowSize()
  const consentAccepted = useConsent()
  let currentDate=new Date()
  currentDate.setDate(currentDate.getDate()+7)
  let aWeekFromCurrentDate=currentDate
  const navigation={
    nextEl: '.announcement-banner__banner-arrow--next',
    prevEl: '.announcement-banner__banner-arrow--prev'
  }
  const pageData=data
  const bannerCount=pageData?.body[0]?.items.filter(slide => !slide.show_specific_banner).length;
  const isSticky=pageData?.sticky_banner;
  const cookieOptions={
    expires: aWeekFromCurrentDate,
    path: "/"
  };
  let contentType = data?.dismiss_banner ? bannerCount==1 ? "Announcement Banner able to dismiss":"Announcement Banner with scroll" : bannerCount==1 ? "Announcement Banner unable to dismiss":"Announcement Banner with scroll";
  const hideAnnouncementBanner=(item) => {
    setCookie("dismissBanner",true,cookieOptions);
    document?.getElementById("header").classList.remove("header--banner")
    document?.getElementById("homepage").classList.remove("generic-template-announcement-banner")
    const contentCTA=item?.cta_text_desktop?.text
    const contentLink=item?.cta_text_link_desktop?.url
    onCloseButtonEvent({
      contentType: contentType,
      contentCTA: contentCTA,
      contentLink: contentLink,
    },consentAccepted)
  }
  const handleScroll=() => {
    window.scrollY<90? setScrollBanner(false):setScrollBanner(true)
  }
  useEffect(() => {
    // add event listener
    window.addEventListener("scroll",handleScroll)
    document.body.classList.remove('overflow-hidden')
    document.documentElement.className=''
    return () => window.removeEventListener("scroll",handleScroll)
  },[scrollBanner])

  let bannerClass=(scrollBanner)? ' scrolled':''


  const onDesktopBannerInteract=(item) => {
    const contentCTA=item?.cta_text_desktop?.text
    const contentLink=item?.cta_text_link_desktop?.url
    onBannerInteractionEvent({
      contentType: contentType,
      contentCTA: contentCTA,
      contentLink: contentLink,
    },consentAccepted)
  }
  const onMobileBannerInteract=(item) => {
    const contentCTA=item?.cta_text_mobile?.text
    const contentLink=item?.cta_text_link_mobile?.url
    onBannerInteractionEvent({
      contentType: contentType,
      contentCTA: contentCTA,
      contentLink: contentLink,
    },consentAccepted)
  }
  useEffect(() => {
    const item=pageData?.body[0]?.items?.[0]
    const contentCTA=item?.cta_text_mobile?.text
    const contentLink=item?.cta_text_link_mobile?.url
    onBannerImpressionEvent({
      contentType: contentType,
      contentCTA: contentCTA,
      contentLink: contentLink,
    },consentAccepted)
    if(pageType!='generic_page' && !cookie?.dismissBanner){
      if(document?.querySelector('.inside-content')){
        document?.getElementById(currentPage)?.classList?.add("inside-content-padding")
      }else{
        document?.getElementById(currentPage)?.classList?.add("custom-page-announcement-banner")
      }
    }
  })
  return (
    !cookie?.dismissBanner&&
    <div className={`announcement-banner ${isSticky? bannerClass:"position-unset"}`}>
      <Swiper
        modules={[Navigation,Autoplay]}
        navigation={navigation}
        initialSlide={0}
        speed={500}
        slidesPerView={1}
        loop={true}
        onSwiper={setSwiper}
      >
        {
          pageData?.body[0]?.items?.map((item,index) => {
            const titleFontSizeDesktop=item?.banner_title_font_size_desktop;
            const titleFontSizeMobile=item?.banner_title_font_size_mobile;
            const titleFontSize=getFontSize(width, titleFontSizeDesktop, titleFontSizeMobile)
            const ctaTextFontSizeDesktop=item?.cta_text_font_size_desktop;
            const ctaTextFontSizeMobile=item?.cta_text_font_size_mobile;
            const ctaTextFontSize=getFontSize(width, ctaTextFontSizeDesktop, ctaTextFontSizeMobile)
            const components={
              heading1: ({children}) => <h1 className="announcement-banner__custom-heading">{children}</h1>,
              paragraph: ({children}) => <p style={{color: item?.banner_title_colour||'#fff',fontSize:titleFontSize}} className="announcement-banner__custom-paragraph">{children}</p>,
              strong: ({children}) => <strong className="announcement-banner__custom-bold">{children}</strong>,
              em: ({children}) => <em className="announcement-banner__custom-italic">{children}</em>,
            };
            return (
              !item?.show_specific_banner&&
              <SwiperSlide style={{backgroundColor: item?.banner_background? item?.banner_background:"#000"}} key={index}>
                <div className='announcement-banner__slider-container'>
                  <div className='announcement-banner__slider-Desktop-container'>
                    <div className='announcement-banner__slider-text'>
                      <PrismicRichText field={item?.banner_title?.richText} components={components} />
                    </div>
                    <div className='announcement-banner__cta-text-container'>
                      {width>=991&&<CustomLink
                      to={item?.cta_text_link_desktop?.url}
                      onClick={()=>onDesktopBannerInteract(item)} className="announcement-banner__cta-text" style={{fontSize:ctaTextFontSize || "17px"}}>{item?.cta_text_desktop?.text}</CustomLink>}
                    </div>
                  </div>
                  {
                    width<991&&
                    <div className='announcement-banner__cta-mobile-container'>
                      <CustomLink
                      to={item?.cta_text_link_mobile?.url}
                      onClick={()=>onMobileBannerInteract(item)}
                      style={{fontSize:ctaTextFontSize || "14px"}}
                      className="announcement-banner__cta-text" > {item?.cta_text_mobile?.text}</CustomLink>
                    </div>
                  }
                </div>
              </SwiperSlide>
            )
          })
        }
        {
          dismissBanner&&bannerCount==1?
            <div>
              <button onClick={() => hideAnnouncementBanner(pageData?.body[0]?.items?.[0])} className='announcement-banner__cross-btn'>
                <FontAwesomeIcon className="fa-solid" size="xl" icon={faXmark} />
              </button>
            </div>
            :
            <div>
              <button className='announcement-banner__banner-arrow announcement-banner__banner-arrow--prev'>
                <span className="visually-hidden">Prev</span>
                <FontAwesomeIcon icon={faChevronLeft} className="fa-solid" size="xl" />
              </button>
              <button className='announcement-banner__banner-arrow announcement-banner__banner-arrow--next'>
                <span className="visually-hidden">Next</span>
                <FontAwesomeIcon icon={faChevronRight} className="fa-solid" size="xl" />
              </button>
            </div>
        }
      </Swiper>
    </div>
  )
}

export default AnnouncementBanner
