import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { AnchorLink } from "../../components/AnchorLink";

import useWindowSize from "../../hooks/useWindowSize";

// constants
import { COLOR } from "../../constants/colors";


export const WyngForm = ({ slice }) => {
  const { width } = useWindowSize()
  // console.log(slice)
  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // Section
  const section = {
    bgColor: slice.primary.section_bg_color || COLOR.WHITE,
    mobileBgImg: slice.primary.section_bg_image?.thumbnails?.mobile.url || '',
    desktopBgImg: slice.primary.section_bg_image?.url || '',
  }

  const isMobile = width <= 768;

  const sectionStyles = {
    backgroundImage: section.desktopBgImg ? `${isMobile ? `url('${section.mobileBgImg}')` : `url('${section.desktopBgImg}')`}` : null,
  }

  const innerContainerStyles = {
    backgroundColor: section.bgColor,
  }

  const isProdVersion = slice.primary.version
  const wyngId = isProdVersion ? slice.primary?.wyng_id : slice.primary?.wyng_id_dev
  const wyngToken = isProdVersion ? slice.primary?.wyng_token :  slice.primary?.wyng_token_dev

  const wyngScript = "//dnsl4xr6unrmf.cloudfront.net/js/campaign.js"

  useEffect(() => {
    // append survey script
    const script = document.createElement('script')
    script.src = wyngScript
    script.async = true
    document.body.appendChild(script)
    script.onload = () => {
      const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {      
          const quizContainer = document.querySelector('.QuizComponent');
          if (quizContainer) {
            const answerButtons = quizContainer.querySelectorAll('.answer-button');
            if (answerButtons.length > 0) {
              const scrollToTop = () => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              };

              answerButtons.forEach((button) => {
                button.addEventListener('click', scrollToTop);
              });
              observer.disconnect();
            }
          }
        });
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    };
    return () => {
      document.body.removeChild(script)
    }
  }, [wyngScript])
 
  return (
      <section className="sign-up" style={sectionStyles} id={`sign-up-${sectionId}`}>
        { anchorLink && <AnchorLink link={anchorLink}/> }
        <div className='sign-up__inner-container' style={innerContainerStyles}>
          <div className='wyng-experience'
               data-wyng-id={wyngId}
               data-wyng-token={wyngToken}
          >
          </div>
        </div>
      </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyWyngForm on PrismicGenericPageDataBodyWyngForm  {
    id
    primary {
      section_id{
        text
      }
      anchor_link{
        text
      }
      section_bg_color
      section_bg_image {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      version
      wyng_id
      wyng_token
      wyng_id_dev
      wyng_token_dev
    }
  }
`;