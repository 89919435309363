// core
import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router';
import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid';

// Components
import Header from './Header'
import Footer from './Footer'
import AgeGate from './AgeGate'
import GeoGate from './GeoGate'
import NewsLetterSignUpPopUp from './NewsLetterSignUpPopUp'
import {setGlobalLangAndCountryEvent, setMoreThanFivePages} from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'
import AnnouncementBanner from './AnnouncementBanner';
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import {useStaticQuery,graphql} from 'gatsby';

const Layout = ({children, currentPage, thankYouPage, activeDocMeta, setPreviousPage = true, noSignUpPopup = false, className = null, showHeader = true, showFooter= true,header_navigation,style,showGenericPageBanner,showWyngPageBanner}) => {
  const location = useLocation()
  const persID = uuidv4();
  const [showAgeGate, setShowAgeGate] = useState(false)
  const [cookies, setCookie] = useCookies()
  const consentAccepted = useConsent()

  useEffect(()=>{
    if(activeDocMeta.lang){
      setGlobalLangAndCountryEvent(activeDocMeta.lang, consentAccepted)
    }
  }, [activeDocMeta.lang, consentAccepted])

  useEffect(()=>{
    if(!cookies?.persID) {
      setCookie('persID', persID, { expires: aWeekFromCurrentDate, path: '/' })
    }
  }, [cookies?.persID])

  useEffect(() => {
    // set previous page
    if(setPreviousPage){
      sessionStorage.setItem("previous_page_url", location.pathname)
    }
  }, [])

  useEffect(() => {
    // set global variable for WYNG
    if(sessionStorage.getItem("mag_global_language") !== activeDocMeta.lang){
      sessionStorage.setItem("mag_global_brand", "WCB");
      sessionStorage.setItem("mag_global_market", "US");
      sessionStorage.setItem("mag_global_language", activeDocMeta.lang == "en-us"? "en" : "es");
    }
  }, [activeDocMeta.lang])

  useEffect(() => {
    let visitedPagesSendDL = localStorage.getItem('visitedPagesSendDL')

    if(visitedPagesSendDL === 'false') {
      return
    }

    const visitedPages = JSON.parse(localStorage.getItem('visitedPages')) || []

    if(visitedPages.length < 5) {
      const currentPage = location.pathname;
      const updatedVisitedPages = [...visitedPages, currentPage];
      localStorage.setItem('visitedPages', JSON.stringify(updatedVisitedPages));
    } else if (visitedPages.length >= 5 && consentAccepted) {
      setMoreThanFivePages(consentAccepted)
      localStorage.setItem('visitedPagesSendDL', 'false')
      visitedPagesSendDL = localStorage.getItem('visitedPagesSendDL')
    }
  }, [consentAccepted]);

  const CURRENT_PAGE_TYPE = {
    DJA: 'dja-page',
    CLAWBACK: 'clawback',
    WHITECLAWGEAR: 'whiteclawgear',
    SUBSCRIBE: 'subscribe'
  }

  // Get Date
  let currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 7)
  let aWeekFromCurrentDate = currentDate

  const ageGateValid = () => {
    setCookie('adult', true, {expires: aWeekFromCurrentDate, path: '/'})
    const urlParams = new URLSearchParams(window.location.search);
    const lidExists = urlParams.has('lid');
    const masubExists = urlParams.has('masub');
    let today = new Date()
    today.setDate(currentDate.getDate() + 365)
    let ayearFromCurrentDate = today
    if (lidExists || masubExists) {
      setCookie('wc_subscriber', true, { expires: ayearFromCurrentDate, path: '/' });
    }
    else{
      setCookie('wc_subscriber', false)
    }
  }

  useEffect(() => {
    // on dev
    const params=new URLSearchParams(location.search);
    if(params.has('ag-disable')) {
      if(!cookies?.adult) {
        ageGateValid();
      }
      setShowAgeGate(false)
    }
    else {
      // on Prod
      if(cookies?.adult){
        setShowAgeGate(false)
      } else {
        setShowAgeGate(true)
      }
    }

    setCookie('wcUserURL', location?.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies?.adult])

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var age = url.searchParams.get("ag");
    var persIDFromURL = url.searchParams.get('persID');
    if (age == "false") {
      setShowAgeGate(false)
      setCookie('adult', true, {expires: aWeekFromCurrentDate, path: '/'})
    }
    if (persIDFromURL) {
      const persIDFromCookie = cookies?.persID;
    
      if (persIDFromCookie !== persIDFromURL) {
        const aWeekFromCurrentDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
        document.cookie = `persID=${persIDFromURL}; path=/; ${aWeekFromCurrentDate ? 'expires=' + aWeekFromCurrentDate.toUTCString() + ';' : ''}`;
      }
    }
  }, [])
  
  const queryData=useStaticQuery(graphql`
    {
      prismicAnnouncementBanner {
      data {
      body {
        ... on PrismicAnnouncementBannerDataBodyBannerSlice {
          id
          items {
            banner_background
            banner_title {
              richText
              html
            }
            banner_title_font_size_desktop
            banner_title_font_size_mobile
            banner_title_colour
            cta_text_desktop {
              text
              html
            }
            cta_text_font_size_desktop
            cta_text_font_size_mobile
            cta_text_link_desktop {
              url
            }
            cta_text_mobile {
              text
              html
            }
            cta_text_link_mobile {
              url
            }
            show_specific_banner
          }
          primary {
            generic_page
            landing_page
            contact_us_page
            events_page
            locator_page
            microsite
            new_product_page
            product_details_page
            rules_page
            survey_page
            terms_and_conditions_page
            unsubscribe_page
            wyng_page
            wishpond_page
          }
        }
      }
      dismiss_banner
      show_banner
      sticky_banner
    }
  }
    }
    `)

  const {data}=useMergePrismicPreviewData(queryData)
  const pageData=data?.prismicAnnouncementBanner?.data
  const showBanner=data?.prismicAnnouncementBanner?.data?.show_banner;
  const showBannerCustomPageType=pageData?.body[0]?.primary;
  const isSticky=pageData?.sticky_banner;
  return (
      <>
        {(currentPage !== CURRENT_PAGE_TYPE.DJA && currentPage !== CURRENT_PAGE_TYPE.CLAWBACK && currentPage !== CURRENT_PAGE_TYPE.WHITECLAWGEAR) &&
            <>
              {/*AnnouncementBanner*/}
              {
                activeDocMeta.lang === "en-us" &&
                showBanner ?
                (showBannerCustomPageType[activeDocMeta?.type]!==false||showBannerCustomPageType[activeDocMeta?.type]===undefined)
                ?
                (activeDocMeta?.type ==="generic_page" || activeDocMeta?.type ==="wyng_page") ?
                ( (showGenericPageBanner || showGenericPageBanner ===null) || (showWyngPageBanner || showWyngPageBanner ===null)) ?
                  <AnnouncementBanner data={pageData} pageType={activeDocMeta?.type} currentPage={currentPage}/>
                : null
                : <AnnouncementBanner data={pageData} pageType={activeDocMeta?.type} currentPage={currentPage}/>
                : null
                : null
              }
              {/* Header */}
              { showHeader && <Header customPage={currentPage || ''} activeDocMeta={activeDocMeta} header_navigation={header_navigation} isSticky={isSticky} showBannerCustomPageType={showBannerCustomPageType} showBanner={showBanner} showGenericPageBanner={showGenericPageBanner} showWyngPageBanner={showWyngPageBanner}/> }
              {/* GeoGate */}
              { currentPage === 'homepage' && <GeoGate /> }
              { showAgeGate && <AgeGate setAgeValid={ageGateValid} activeDocMeta={activeDocMeta}/>}
              { currentPage ? <main id={currentPage} className={thankYouPage ? "thank-you" : className } style={style}>{children}</main> : <main className={className} style={style}>{children}</main>}

              {/* Footer */}
              { showFooter && <Footer activeDocMeta={activeDocMeta} subscribeForm={currentPage === 'unsubscribe' ? false : true}
                       persID={persID}/> }

              {/* NewsLetter PopUp */}
              { noSignUpPopup  === false && <NewsLetterSignUpPopUp />}
            </>
        }

        {currentPage === CURRENT_PAGE_TYPE.DJA &&
            <>
              <main id={currentPage}>{children}</main>
              <Footer activeDocMeta={activeDocMeta} persID={persID}/>
            </>
        }

        {(currentPage === CURRENT_PAGE_TYPE.CLAWBACK || currentPage === CURRENT_PAGE_TYPE.WHITECLAWGEAR) &&
            <>
              { showAgeGate && <AgeGate setAgeValid={ageGateValid} activeDocMeta={activeDocMeta}/>}
              <main id={currentPage} className={!showAgeGate ? 'agegate-valid' : ''}>{children}</main>
              <Footer activeDocMeta={activeDocMeta} persID={persID}/>
            </>
        }
      </>
  )
}


export default Layout