import React, { useEffect, useRef, useState } from "react";

// components
import CountrySelector from "./CountrySelector";
import ImgWithFallback from "./ImgWithFallback";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import globe from "../assets/images/icons/globe.svg";
import whiteclawLogoSrc from "../assets/images/logo-blk.webp";
import whiteclawLogoFallbackSrc from "../assets/images/logo-blk.png";

const AgeGate = ({ setAgeValid, activeDocMeta }) => {
  const { lang } = activeDocMeta ? activeDocMeta : { lang: "en-us" };
  const [minorMsg, setMinorMsg] = useState(false);
  const ageGateContent = [];
  const dayInputRef = useRef(null);
  const yearInputRef = useRef(null);
  const monthInputRef = useRef(null);

  useEffect(() => {
      if(!minorMsg){
        if(lang === "en-us" && monthInputRef.current)
          monthInputRef.current.focus();
        else if(lang != "en-us" && dayInputRef.current)
          dayInputRef.current.focus();
      }
  },[minorMsg]);

  const validationsEN = {
    invalidMonth: "Month must be between 1 and 12",
    monthRequired: "Month is required",
    invalidDay: "Invalid day for selected month",
    dayRequired: "Day is required",
    invalidAge: "Please come back and visit us when you're 21.",
    yearRequired: "Year is required",
    submitBtn: "Confirm",
  };

  const validationsES = {
    invalidMonth: "El mes debe estar entre 1 y 12",
    monthRequired: "Se requiere el mes",
    invalidDay: "Día no válido para el mes seleccionado",
    dayRequired: "Se requiere el día",
    invalidAge: "Lo sentimos, debes tener 21+ años para entrar.",
    yearRequired: "Se requiere el año",
    submitBtn: "Confirmar",
  };

  const validations = lang === "en-us" ? validationsEN : validationsES;

  // country select functions
  const [showCountrySelector, setShowCountrySelector] = useState(false);
  const closeCountrySelector = () => setShowCountrySelector(false);
  const openCountrySelector = () => setShowCountrySelector(true);

    // DOB validation
    const DOBFormSchema = Yup.object().shape({
        month: Yup.number()
            .min(1, validations.invalidMonth)
            .max(12, validations.invalidMonth)
            .required(validations.monthRequired),
        day: Yup.number()
            .test("valid-day", validations.invalidDay, function (value) {
            const month = this.parent.month;
            if (!value || !month) {
                return true;
            }
            const year = this.parent.year || new Date().getFullYear();
            const daysInMonth = new Date(year, month, 0).getDate();
            return value >= 1 && value <= daysInMonth;
            })
            .required(validations.dayRequired),
        year: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(4, lang === "en-us" ? "Must be exactly 4 characters" :"Debe tener exactemente cuatro caractereres")
            .max(4, lang === "en-us" ? "Must be exactly 4 characters" :"Debe tener exactemente cuatro caractereres")
            .required(validations.yearRequired),
    });

  if (!minorMsg) {
    // age gate
    ageGateContent.push(
      <div key="1">
        <div className="row">
          <div className="col-12">
            <h1 className="age-gate__intro">{lang === "en-us" ? "WHAT IS YOUR DATE OF BIRTH?" : "¿CUÁL ES TU FECHA DE NACIMIENTO?"}</h1>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <Formik
              initialValues={{ day: "", month: "", year: "" }}
              validationSchema={DOBFormSchema}
              onSubmit={(values) => {
                const birthdate = new Date(values.year, values.month - 1, values.day);
                const birthdateYear = birthdate.getFullYear();
                const birthdateTimestamp = Math.floor(birthdate.getTime() / 1000);
                const todayTimestamp = Math.floor(Date.now() / 1000);
                const todayYear = new Date(todayTimestamp * 1000).getFullYear();
                const ageInSeconds = todayTimestamp - birthdateTimestamp;
                const ageInYears = ageInSeconds / (60 * 60 * 24 * 365.25);
                const validAgeWholeYears = 21;
                const ageValid = ageInYears >= validAgeWholeYears;

                const isTodayBirthday =
                  new Date(todayTimestamp * 1000).getDate() === birthdate.getDate() &&
                  new Date(todayTimestamp * 1000).getMonth() === birthdate.getMonth() &&
                  todayYear - birthdateYear === validAgeWholeYears;

                if (ageValid || isTodayBirthday) {
                  setAgeValid(true);
                } else {
                  setMinorMsg(true);
                }
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form id="frm-contact-form" autoComplete="off">
                  <div className="row age-gate-gap">
                    {lang === "en-us" ? (
                      <>
                        <div className="col-12 col-lg-4 form-group form-text-field">
                          {/* Month */}
                          <Field
                            type="number"
                            id="form-birthdate-month"
                            className='form-control'
                            name="month"
                            innerRef={monthInputRef}
                            placeholder="MM"
                            onChange={(event) => {
                              event.target.value = event.target.value.slice(0, 2);
                              if (event.target.value.length === 2 && event.target.value >= 1 && event.target.value <= 12) {
                                dayInputRef.current.focus();
                              }
                              handleChange(event);
                            }}
                            onBlur={(event) => {
                              handleBlur(event);
                              const { value } = event.target;
                              if (value.length === 1 && parseInt(value, 10) > 0) {
                                handleChange({ target: { name: "month", value: "0" + value } });
                              }
                            }}
                            value={values.month}
                            min={1}
                            max={12}
                          />
                          <div className="age-gate__error text-danger">{errors.month && touched.month && errors.month}&nbsp;</div>
                        </div>
                        <div className="col-12 col-lg-4 form-group form-text-field">
                          {/* Day */}
                          <Field name="day">
                            {({ field, form }) => (
                              <input
                                {...field}
                                type="number"
                                id="form-birthdate-day"
                                className="form-control"
                                placeholder="DD"
                                ref={dayInputRef} 
                                onChange={(event) => {
                                  const value = event.target.value.slice(0, 2); 
                                  form.setFieldValue("day", value); // Update Formik field value
                                  if (value.length === 2 && value > 0 && value < 32) {
                                    yearInputRef.current?.focus(); 
                                  }
                                }}
                                onBlur={(event) => {
                                  const { value } = event.target;
                                  form.handleBlur(event); 
                                  if (value.length === 1 && parseInt(value, 10) > 0) {
                                    form.setFieldValue("day", "0" + value); 
                                  }
                                }}
                                value={field.value} 
                                min={1}
                                max={31}
                              />
                            )}
                          </Field>
                          <div className="age-gate__error text-danger">{errors.day && touched.day && errors.day}&nbsp;</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12 col-lg-4 form-group form-text-field">
                          {/* Day */}
                          <Field name="day">
                            {({ field, form }) => (
                              <input
                                {...field}
                                type="number"
                                id="form-birthdate-day"
                                className="form-control"
                                placeholder="DD"
                                ref={dayInputRef} 
                                onChange={(event) => {
                                  const value = event.target.value.slice(0, 2); 
                                  form.setFieldValue("day", value); // Update Formik field value
                                  if (value.length === 2 && value > 0 && value < 32) {
                                    monthInputRef.current?.focus(); 
                                  }
                                }}
                                onBlur={(event) => {
                                  const { value } = event.target;
                                  form.handleBlur(event); 
                                  if (value.length === 1 && parseInt(value, 10) > 0) {
                                    form.setFieldValue("day", "0" + value); 
                                  }
                                }}
                                value={field.value} 
                                min={1}
                                max={31}
                              />
                            )}
                          </Field>
                          <div className="age-gate__error text-danger">{errors.day && touched.day && errors.day}&nbsp;</div>
                        </div>
                        <div className="col-12 col-lg-4 form-group form-text-field">
                          {/* Month */}
                          <Field
                            type="number"
                            id="form-birthdate-month"
                            className='form-control'
                            name="month"
                            placeholder="MM"
                            onChange={(event) => {
                              event.target.value = event.target.value.slice(0, 2);
                              handleChange(event);
                              if (event.target.value.length === 2 && event.target.value > 0 && event.target.value < 13) {
                                yearInputRef.current.focus();
                              }
                            }}
                            onBlur={(event) => {
                              handleBlur(event);
                              const { value } = event.target;
                              if (value.length === 1 && parseInt(value, 10) > 0) {
                                handleChange({ target: { name: "month", value: "0" + value } });
                              }
                            }}
                            value={values.month}
                            min={1}
                            max={12}
                            innerRef={monthInputRef}
                          />
                          <div className="age-gate__error text-danger">{errors.month && touched.month && errors.month}&nbsp;</div>
                        </div>
                      </>
                    )}
                    <div className="col-12 col-lg-4 form-group form-text-field">
                      {/* Year */}
                      <Field
                        type="number"
                        id="form-birthdate-year"
                        className='form-control'
                        name="year"
                        placeholder="YYYY"
                        innerRef={yearInputRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.year}
                      />
                      <div className="age-gate__error text-danger">{errors.year && touched.year && errors.year}&nbsp;</div>
                    </div>
                  </div>
                  <div className="row justify-content-center age-gate-gap">
                    <div className="col-12 col-lg-4 form-group form-text-field form-btn-container d-flex my-0 mb-sm-3 mt-sm-2">
                      {/* Submit Btn */}
                      <button className="form-btn mx-0 w-100" type="submit" id="submit-contact-form">
                        {validations.submitBtn}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  } else {
    // minor entered
    ageGateContent.push(
      <div key="2">
        <div className="row age-gate-gap">
          <div className="col-12">
            <h1 className="age-gate__intro">{validations.invalidAge}</h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="modal modal-age-gate">
      <div className="age-gate">
        <div className="age-gate__content">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <ImgWithFallback classNameImg={"age-gate__logo"} src={whiteclawLogoSrc} fallbackSrc={whiteclawLogoFallbackSrc} alt="White Claw Logo" />
            </div>
          </div>

          {/* AGE GATE DYNAMIC CONTENT */}
          {ageGateContent}

          <div className="row">
            <div className="col-12">
              <div className="region-select us">
                <h4>
                  <img src={globe} alt="Globe Icon" /> Country / Language
                </h4>

                <button type="button" className="region-selected d-flex justify-content-center align-items-center" onClick={openCountrySelector} onKeyDown={openCountrySelector}>
                  <div className="region-selected__flag"></div>
                  <div className="region-selected__country">
                    {lang === "en-us" ? <span className="country-name">UNITED STATES</span> : <span className="country-name">ESTADOS UNIDOS</span>}
                    <br />
                    <span className="country-lang">{lang === "en-us" ? "English" : "Español"}</span>
                  </div>

                  <FontAwesomeIcon icon={faChevronRight} className="fa-solid" />
                </button>
              </div>
            </div>
          </div>
          {lang === "en-us" ? (
            <div className="age-gate__terms">
              <p className="age-gate__terms-text">Please Drink Responsibly. All Registered Trademarks, used under license by White Claw Seltzer Works, Chicago, IL</p>
            </div>
          ) : (
            <div className="age-gate__terms">
              <p className="age-gate__terms-text">Por favor bebe responsablemente. Todas las marcas registradas, son utilizadas bajo licencia por White Claw Seltzer Works, Chicago, IL,</p>
            </div>
          )}
        </div>
      </div>
      <CountrySelector showCountrySelector={showCountrySelector} closeCountrySelector={closeCountrySelector} activeDocMeta={lang} />
    </div>
  );
};

export default AgeGate;