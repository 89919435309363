import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import {useStaticQuery,graphql} from 'gatsby';

export const BannerQuery=() => {
    const queryData=useStaticQuery(graphql`
        {
          prismicAnnouncementBanner {
          data {
          body {
            ... on PrismicAnnouncementBannerDataBodyBannerSlice {
              id
              items {
                banner_background
                banner_title {
                  richText
                  html
                }
                banner_title_font_size_desktop
                banner_title_font_size_mobile
                banner_title_colour
                cta_text_desktop {
                  text
                  html
                }
                cta_text_font_size_desktop
                cta_text_font_size_mobile
                cta_text_link_desktop {
                  url
                }
                cta_text_mobile {
                  text
                  html
                }
                cta_text_link_mobile {
                  url
                }
                show_specific_banner
              }
              primary {
                generic_page
                landing_page
                contact_us_page
                events_page
                locator_page
                microsite
                new_product_page
                product_details_page
                rules_page
                survey_page
                terms_and_conditions_page
                unsubscribe_page
                wyng_page
                wishpond_page
              }
            }
          }
          dismiss_banner
          show_banner
          sticky_banner
        }
      }
        }
        `)

    const {data}=useMergePrismicPreviewData(queryData)
    const pageData=data?.prismicAnnouncementBanner?.data
    const showBanner=data?.prismicAnnouncementBanner?.data?.show_banner;
    const showBannerCustomPageType=pageData?.body[0]?.primary;
    const isSticky=pageData?.sticky_banner;
    return {pageData,showBanner,showBannerCustomPageType,isSticky};
}

//module.exports={BannerQuery};
