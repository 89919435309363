// core
import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'

// Components
import Header from './Header'
import Footer from './Footer'
import AgeGate from './AgeGate'
import {useStaticQuery,graphql} from 'gatsby';
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';

const LayoutEmbeddedPage = ({children, activeDocMeta = null, showHeaderFooter = false}) => {
  const [showAgeGate, setShowAgeGate] = useState(false)
  const pID = uuidv4()
  const [cookies, setCookie] = useCookies()
  const [persID, setPersID] = useState('')

  // Get Date
  let currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 7)
  let aWeekFromCurrentDate = currentDate

  const ageGateValid = () => { setCookie('adult', true,{expires: aWeekFromCurrentDate, path: '/'})}


  useEffect(()=> {

    if(cookies?.adult){
      setShowAgeGate(false)
    } else {
      setShowAgeGate(true)
    }

  },[cookies?.adult])

  useEffect(()=>{
    if(cookies?.persID) {
      setPersID(cookies.persID)
    } else {
      setCookie('persID', pID, { expires: aWeekFromCurrentDate, path: '/' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.persID])
  const queryData=useStaticQuery(graphql`
    {
      prismicAnnouncementBanner {
      data {
      body {
        ... on PrismicAnnouncementBannerDataBodyBannerSlice {
          id
          items {
            banner_background
            banner_title {
              richText
              html
            }
            banner_title_font_size_desktop
            banner_title_font_size_mobile
            banner_title_colour
            cta_text_desktop {
              text
              html
            }
            cta_text_font_size_desktop
            cta_text_font_size_mobile
            cta_text_link_desktop {
              url
            }
            cta_text_mobile {
              text
              html
            }
            cta_text_link_mobile {
              url
            }
            show_specific_banner
          }
          primary {
            generic_page
            landing_page
            contact_us_page
            events_page
            locator_page
            microsite
            new_product_page
            product_details_page
            rules_page
            survey_page
            terms_and_conditions_page
            unsubscribe_page
            wyng_page
            wishpond_page
          }
        }
      }
      dismiss_banner
      show_banner
      sticky_banner
    }
  }
    }
    `)

  const {data}=useMergePrismicPreviewData(queryData)
  const pageData=data?.prismicAnnouncementBanner?.data
  const showBanner=data?.prismicAnnouncementBanner?.data?.show_banner;
  const showBannerCustomPageType=pageData?.body[0]?.primary;
  const isSticky=pageData?.sticky_banner;

  return (
      <>
        {/* Header */}
        {showHeaderFooter && <Header customPage={'wishpond'} activeDocMeta={activeDocMeta} persID={persID} isSticky={isSticky} showBannerCustomPageType={showBannerCustomPageType} showBanner={showBanner} />}

        {showAgeGate && <AgeGate setAgeValid={ageGateValid}/>}
        <main>{children}</main>

        {/* Footer */}
        {showHeaderFooter && <Footer activeDocMeta={activeDocMeta} subscribeForm={true} />}
      </>
  )
}

export default LayoutEmbeddedPage