import React, { useState, useEffect } from 'react';
import { PrismicRichText } from "@prismicio/react";
import { graphql } from 'gatsby';
// assets
import { getButtonCustomStyles } from "../../assets/js";
import useWindowSize from "../../hooks/useWindowSize";

export const BaazarVoiceReviewButtonComponent = ({ slice }) => {
  const { width } = useWindowSize();
  const [showReview, setShowReview] = useState(false);
  const sectionId = slice?.id || '';

  // CTA Button
  const ctaButton = {
    text: slice.primary.cta_button_text?.text || ''
  };

  // CTA Styles
  const buttonColorSet = {
    color: slice.primary.cta_button_text_color,
    bg: slice.primary.cta_button_background_color,
    bgT: slice.primary.cta_button_bg_color_transparency,
    border: slice.primary.cta_button_border_color,
    colorHover: slice.primary.cta_button_text_color_hover,
    bgHover: slice.primary.cta_button_bg_color_hover,
    bgHoverT: slice.primary.cta_button_bg_color_hover_transparency,
    borderHover: slice.primary.cta_button_border_color_hover,
    borderRadius: slice.primary.cta_button_border_radius || "0",
  };
  const showProductName=slice?.primary?.show_product_name
  const showProductImage=slice?.primary?.show_product_image
  const showProductRating=slice?.primary?.show_product_rating






  const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet);

  const isMobile = width <= 768;
  const [selectedProductIdBV,setSelectedProductIdBv]=useState(null);


  useEffect(() => {
    if (showReview) {
      document.body.style.overflow = 'hidden';
      document.body.style.overflow = 'auto';
    }

    const script = document.createElement("script");
    script.src = "https://apps.bazaarvoice.com/deployments/white_claw/main_site/production/en_US/bv.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      document.body.style.overflow = 'auto';
    };
  }, [showReview]);

  const handleWriteReviewClick = (id) => {
    setShowReview(true);
    setSelectedProductIdBv(id)
  };

  return (
    <section id={`baazar-voice-review-button-${sectionId}`}>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            {
              slice?.items?.map((data,idx) => {
                const productName=data?.product_details?.document?.data?.product_name;
                const productImageSrc=data?.product_details?.document?.data?.product_image_png?.url;
                const productIdBV=data?.product_details?.document?.data?.product_id?.split(",").splice(0,1).join("");
                const productUrl=data?.product_details?.document?.url;
                return (
                  <div className="col-6 col-md-4 col-lg-3 mb-5" key={idx}>
                    <div className="d-flex h-100 flex-column align-items-end text-center mx-auto">
                      <div className='mx-auto mt-auto mb-auto align-self-center'>
                      {
                        showProductImage&&
                        <img src={productImageSrc} className='img-fluid bv-product-image mx-auto' />
                      }
                      </div>
                      <div className='mx-auto align-self-end product-title'>
                        {
                          showProductName&&
                          <div className="product-range-content__name-text text-center name-text-mobile">
                            <PrismicRichText field={productName.richText} />
                          </div>
                        }
                        {
                          showProductRating&&
                          <div data-bv-show="inline_rating"
                            className='bv-inline-rating-container'
                            data-bv-product-id={productIdBV}
                            data-bv-redirect-url={productUrl}>
                          </div>
                        }
                      </div>
                      <button
                        title={ctaButton.text}
                        className={`full-width-text__cta-button review-button`}
                        style={ctaButtonStyle}
                        onClick={() => handleWriteReviewClick(productIdBV)}
                      >
                        {ctaButton.text}
                      </button>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      {showReview&&(
        <div data-bv-show="inpage_submission" data-bv-product-id={selectedProductIdBV} />
      )}
    </section>
  );
};

export const fragment = graphql`
  fragment PageDataBodyBaazarVoiceReviewButton on PrismicGenericPageDataBodyBaazarVoiceReviewButton {
    id
    primary {
      cta_button_background_color
      cta_button_bg_color_hover
      cta_button_bg_color_transparency
      cta_button_border_color_hover
      cta_button_border_radius
      cta_button_text {
        raw
        richText
        text
        html
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_bg_color_hover_transparency
      show_product_image
      show_product_name
      show_product_rating
    }
    items {
      product_details {
      document {
        ... on PrismicProductDetailsPage {
          url
          data {
            product_id
            product_name {
              richText
              text
            }
            product_image_png {
              alt
              url
            }
          }
        }
      }
    }
  }
}
`;
